import React, { useEffect, useState, useContext } from "react";
import { ReviewContext } from "./ReviewProvider";
import { MarkdownEditor } from "../Markdown/Markdown";
import { useTranslation } from "react-i18next";

const useCategoryAnswerSelections = (categoryId, category) => {
  const { doctorSelections } = useContext(ReviewContext);

  const selectedCategoryResults = category.results
    .map((result) => result.answers)
    .flat();

  const statements = doctorSelections
    .filter((selection) =>
      selectedCategoryResults.some((option) => option?.id === selection?.id)
    )
    .sort((a, b) => (a?.id > b?.id ? 1 : -1));
  return statements;
};

export const useCategoryAnswerString = (selections, groups, category) => {
  const [string, setString] = useState();

  const categoryAnswer = groups?.find((g) => g.id === category.id)["answer"];

  useEffect(() => {
    const stringArray = [...selections, categoryAnswer].map(
      (selection) => selection?.value
    );
    const htmlString = "<p>" + stringArray.join("</p><p>") + "</p>";
    const string = stringArray.length === 0 ? null : htmlString;

    setString(string);
  }, [categoryAnswer, selections]);

  return string;
};

const CategorySummary = ({ category, resultCategories }) => {
  //const textInputRef = useRef(null);
  const [toggledTextArea, setToggledTextArea] = useState();
  const selections = useCategoryAnswerSelections(category.id, category);
  const {
    groups,
    repliedSections,
    updateCommentGeneratedSections,
    setCategorySummary,
    createStatement,
  } = useContext(ReviewContext);

  const { t } = useTranslation();
  const [textValue, setTextValue] = useState(" ");
  const [loading, setLoading] = useState(false);

  const summarySelection = groups.find((g) => g.id === category.id)["answer"];

  useEffect(() => {
    setToggledTextArea(false);
  }, [summarySelection]);

  const handleCloseTextAreaIfUserResetSomeAnswer = () => {
    const categroyReplied = repliedSections?.some(
      (section) => section.id == category.id
    );

    if (toggledTextArea && !categroyReplied) {
      setToggledTextArea(false);
    }
  };

  useEffect(() => {
    handleCloseTextAreaIfUserResetSomeAnswer();
  }, [repliedSections]);

  return (
    <div className="card  padding-1 margin-1 margin-bottom">
      <h1 className="weight-500 size-medium color-purple margin-1 margin-bottom">
        {category.category} {t("doctor_review.comment")}
      </h1>
      {!toggledTextArea ? (
        <div className="card text-center padding-3 padding-top category-summary-button-container background-gray">
          {loading ? (
            <div className="margin-3 margin-bottom">
              {t("loading_spinner.loading")}
            </div>
          ) : (
            <button
              className="button margin-3 margin-bottom"
              onClick={() => {
                setLoading(true);
                createStatement(
                  (statement) => {
                    if (statement.length > 0) {
                      setTextValue(statement);
                    }
                    setToggledTextArea(true);
                    updateCommentGeneratedSections(category, "add");
                    setLoading(false);
                  },
                  selections,
                  category
                );
              }}
            >
              {t("doctor_review.create_a_comment")}
            </button>
          )}
        </div>
      ) : (
        <MarkdownEditor
          draftJsMarkdown={textValue}
          onBlur={(value) => {
            setCategorySummary(value, category, "writtenAnswer");
          }}
        />
      )}
    </div>
  );
};
export default CategorySummary;
