import React, { useEffect, useState } from "react";
import { isBefore, subMonths, parse } from "date-fns";
import axios from "axios";
import { Link } from "react-router-dom";
import { Subscribe } from "unstated";

import { Info, ShoppingCart, Archive } from "react-feather";
import { getColorCodes } from "./../theme/colorCoding";
import env from "../env";
import { Flipper } from "../flipper";

// Components
import ResultCategory from "./../components/ResultCategory";
import TestResult from "./../components/TestResult";
import LoadingSpinner from "./../components/LoadingSpinner";
import ErrorMessage from "./../components/ErrorMessage";
import SuggestionSection from "./../components/SuggestionSection";
import ResultSummaryCard from "./../components/ResultSummary/ResultSummaryCard";
import NoticeText from "./../components/NoticeText";

import FilteringHeader from "./../components/FilteringHeader/FilteringHeader";
import OrderSelector from "./../components/OrderSelector";
import OrdersContainer from "../containers/OrdersContainer";

import ErrorBoundary from "./../components/ErrorBoundary";
import { withTranslation, Trans } from "react-i18next";
import UserDoctorReview from "../components/UserDoctorReview";
import UserDoctorReviewAd from "../components/UserDoctorReviewAd";

const selectStyles = {
  control: (styles) => {
    return {
      ...styles,
      borderRadius: "0.25rem",
      boxShadow: "none",
      border: "2px solid #e2ecf6",
      background: "white",
      fontSize: "16px",
      padding: "4px 2px",
    };
  },
  indicatorSeparator: (styles) => ({
    ...styles,
    background: "transparent",
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: "#6e9fd5",
  }),
  valueContainer: (styles) => ({
    ...styles,
  }),
};

const Home = (props) => {
  const [orderChangeSpinner, setOrderChangeSpinner] = useState(false);
  const [colorCodes, setColorCodes] = useState();
  const [profile, setProfile] = useState({});
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [currentUser, setCurrentUser] = useState(props.currentUser);
  const [filteredTests, setFilteredTests] = useState(null);
  const [activeSummaryFilters, setActiveSummaryFilters] = useState([]);
  const { t } = props;
  const selectedLanguage = props.i18n?.language;

  const locationState = props.location.state;

  useEffect(() => {
    fetchData();

    if (locationState && locationState.scrollY) {
      window.scrollTo(0, locationState.scrollY);
    }

    const codes = getColorCodes();
    setColorCodes(codes);
  }, [locationState]);

  useEffect(() => {
    if (orderChangeSpinner) {
      window.setTimeout(() => {
        setOrderChangeSpinner(false);
      }, 1000);
    }
  }, [orderChangeSpinner]);

  function fetchData() {
    axios
      .get("/api/profile")
      .then((response) => {
        setLoadingProfile(false);
        setProfile(response.data);
      })
      .catch((error) => {
        setLoadingProfile(false);
      });

    axios
      .get("/api/user")
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {});
  }

  return (
    //hom2
    <ErrorBoundary>
      <Subscribe to={[OrdersContainer]}>
        {(orders) => {
          return (
            <React.Fragment>
              {renderProfileSummary(props, profile, orders.state.list)}
              <div className="padding-2 padding-top margin-0-75 margin-bottom">
                <div className="container">
                  <div className="row">
                    <div className="col-xs-12">
                      {renderContent({
                        props,
                        orders,
                        filteredTests,
                        colorCodes,
                        currentUser,
                        orderChangeSpinner,
                        setFilteredTests,
                        activeSummaryFilters,
                        setActiveSummaryFilters,
                        setOrderChangeSpinner,
                        selectedLanguage,
                        t,
                      })}
                    </div>
                  </div>
                </div>
              </div>

              {props.bloodTestEnabled &&
                props.match.path === "/orders/:id" &&
                orders.state.active &&
                orders.state.list &&
                orders.state.list.length > 0 && (
                  <SuggestionSection
                    orders={orders.state.list}
                    identityNumber={currentUser.identity_number}
                    activeOrder={orders.state.active}
                    colorCodes={colorCodes}
                    selectedLanguage={selectedLanguage}
                    hideEditableSuggestions
                  />
                )}
            </React.Fragment>
          );
        }}
      </Subscribe>
    </ErrorBoundary>
  );
};

function calculateBMI(weight, height) {
  let BMI = Math.round((weight / Math.pow(height / 100, 2)) * 10) / 10;
  BMI = BMI.toString();
  BMI = BMI.replace(".", ",");
  return BMI;
}

function renderProfileUpdateNotice(t) {
  return (
    <div className="notice margin-1 margin-bottom">
      <div className="notice__icon">
        <Info />
      </div>
      <div className="notice__content">
        <Trans i18nKey="profile_update_notice">
          <Link to="/profile" className="link" />
        </Trans>
      </div>
    </div>
  );
}

function renderOrder({
  props,
  colorCodes,
  orders,
  filteredTests,
  activeSummaryFilters,
  graphView,
  currentUser,
  selectedLanguage,
  t,
}) {
  if (
    props.bloodTestEnabled &&
    props.match.path === "/orders/:id" &&
    orders.state.list &&
    orders.state.list.length > 0
  ) {
    return renderCategories(
      orders.state.active,
      orders.state.list,
      filteredTests,
      activeSummaryFilters,
      graphView,
      colorCodes,
      currentUser,
      selectedLanguage,
      t,
    );
  }

  return renderEmptyPage(props);
}

function renderContent({
  props,
  orders,
  filteredTests,
  graphView,
  colorCodes,
  currentUser,
  orderChangeSpinner,
  setFilteredTests,
  activeSummaryFilters,
  setActiveSummaryFilters,
  setOrderChangeSpinner,
  selectedLanguage,
  t,
}) {
  if (orders.state.loading) {
    return (
      <div className="flex justify-center margin-2 margin-top">
        <LoadingSpinner text={t("render_content.loading_results")} />
      </div>
    );
  } else if (props.match.path === "/orders/:id" && orders.state.error) {
    return (
      <div className="flex justify-center margin-2 margin-top">
        <ErrorMessage
          text={t("render_content.results_loading_failed")}
          callback={orders.load}
        />
      </div>
    );
  } else if (orders.state.list && orders.state.list.length > 0) {
    return (
      <div id="testResults">
        {props.bloodTestEnabled &&
          props.match.path === "/orders/:id" &&
          orders.state.active &&
          orders.state.active.groups.length > 0 &&
          orders.state.list &&
          orders.state.list.length > 0 && (
            <FilteringHeader
              allTests={orders.state.active}
              filteredTests={filteredTests}
              activeTest={orders.state.active}
              activeSummaryFilters={activeSummaryFilters}
              setActiveSummaryFilters={(filters) =>
                setActiveSummaryFilters(filters)
              }
              setFilteredTests={(tests) => setFilteredTests(tests)}
            />
          )}

        {env.APP_ID === "puhti" && (
          <div>
            <h2 className="size-huge color-purple weight-500 margin-0-25 margin-bottom">
              {t("render_content.disclaimer_title")}
            </h2>
            <div className="color-purple size-medium margin-1-25 margin-bottom">
              {t("render_content.disclaimer_subtitle")}
            </div>
          </div>
        )}

        <h1 className="weight-500 size-huge color-purple margin-1 margin-bottom">
          {t("render_content.results")}
        </h1>
        <br />
        <div className="margin-1-5 margin-bottom">
          <OrderSelector
            orderChangeSpinner={orderChangeSpinner}
            bloodTestEnabled={props.bloodTestEnabled}
            match={props.match}
            history={props.history}
            orders={orders}
            selectStyles={selectStyles}
            setFilteredTests={setFilteredTests}
            setOrderChangeSpinner={setOrderChangeSpinner}
          >
            <div className="margin-1 margin-bottom">
              {orders.state.active &&
                orders.state.active.doctor_review_available && (
                  <UserDoctorReview
                    orderNumber={orders.state.active.order_number}
                  />
                )}

              {env.APP_ID === "vital" &&
                orders.state.active &&
                orders.state.active.doctor_review_purchasable && (
                  <UserDoctorReviewAd
                    orderNumber={orders.state.active.order_number}
                  />
                )}

              {Flipper.enabled("orderSummary") &&
                orders.state.active &&
                orders.state.active?.summary && (
                  <ResultSummaryCard order={orders.state.active} />
                )}
            </div>
          </OrderSelector>
        </div>
        {env.APP_ID === "vital" &&
          orders.state.active &&
          orders.state.active.laboratory_name && (
            <div>
              <h2 className="size-large color-purple weight-500 margin-0-25 margin-bottom">
                {t("result_summary.laboratory")}
              </h2>
              <div className="color-purple size-medium margin-1-25 margin-bottom">
                {orders.state.active.laboratory_name}
              </div>
            </div>
          )}

        {renderOrder({
          props,
          colorCodes,
          orders,
          filteredTests,
          activeSummaryFilters,
          graphView,
          currentUser,
          selectedLanguage,
          t,
        })}
      </div>
    );
  } else {
    return renderEmptyPage(props);
  }
}

function renderEmptyPage(props) {
  const { t } = props;
  return (
    <div>
      <div className="margin-2 margin-y flex-y align-start">
        <h1 className="weight-500 size-large color-purple margin-1 margin-bottom">
          {t("render_empty_page.i_have_not_bought_test")}
        </h1>
        <a
          href={t("main_navigation.store_link")}
          target="_blank"
          rel="noopener noreferrer"
          className="button button--icon button--orange"
        >
          <ShoppingCart className="width-1 height-1 margin-0-25 margin-right" />
          {t("render_empty_page.go_to_online_store")}
        </a>
        <div className="row">
          <div className="col-xs-12 col-l-10">
            <div className="margin-1 margin-y">
              <p>{t("render_empty_page.puhti_report_disclaimer")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function showProfileUpdatePrompt(orders, currentUser, profile) {
  const { defer_profile_prompt_until } = currentUser;
  const today = new Date();

  const deferProfilePromptUntil = parse(defer_profile_prompt_until);

  if (
    isBefore(deferProfilePromptUntil, today) &&
    profile.hasOwnProperty("id") &&
    orders &&
    orders.length
  ) {
    const updatedAt = parse(profile.updated_at);
    const monthAgo = subMonths(today, 1);

    // Show the prompt if the last update was over a month ago
    if (isBefore(updatedAt, monthAgo)) {
      return true;
    }

    if (orders.length) {
      const lastOrder = orders[0];
      const orderTime = parse(lastOrder.order_time);

      // Show the prompt if the update was before the latest order
      if (isBefore(updatedAt, orderTime)) {
        return true;
      }
    }
  }

  // Default to no prompt
  return false;
}

function renderProfileSummary(props, profile, orders) {
  const { currentUser, t } = props;
  const { weight, height } = profile;
  const profileCountryFi = env.PROFILE_COUNTRY === "fi";
  const profileDisabled = env.PROFILE_DISABLED === "true";
  return (
    <div className="header">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            {!profileDisabled &&
              showProfileUpdatePrompt(orders, currentUser, profile) &&
              renderProfileUpdateNotice(t)}
            <h1 className="weight-500 size-huge color-purple margin-1 margin-bottom">
              {t("render_profile_summary.welcome")} {currentUser.first_name}
            </h1>
            {profileCountryFi && !profileDisabled && (
              <div className="stats">
                <div className="stats__item">
                  <div className="color-gray">
                    {t("render_profile_summary.bmi")}
                  </div>
                  <div className="size-large weight-500">
                    {weight && height ? calculateBMI(weight, height) : "—"}
                  </div>
                </div>
                <div className="stats__item">
                  <div className="color-gray">
                    {t("render_profile_summary.exercise_amount_per_week")}
                  </div>
                  <div className="size-large weight-500">
                    {profile.weekly_exercise
                      ? `${profile.weekly_exercise} ${
                          profile.weekly_exercise === 1
                            ? t("render_profile_summary.hour")
                            : t("render_profile_summary.hours")
                        }`
                      : t("render_profile_summary.none")}
                  </div>
                </div>
                <div className="stats__item">
                  <div className="color-gray">
                    {t("render_profile_summary.sleep_amount_per_day")}
                  </div>
                  <div className="size-large weight-500">
                    {profile.sleep
                      ? `${profile.sleep} ${
                          profile.sleep === 1
                            ? t("render_profile_summary.hour")
                            : t("render_profile_summary.hours")
                        }`
                      : "—"}
                  </div>
                </div>
              </div>
            )}

            {profileCountryFi && !profileDisabled && (
              <div className="margin-0-5 margin-top">
                <Link to="/profile" className="color-blue-dark">
                  {t("render_profile_summary.update_profile")}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function renderCategories(
  activeOrder,
  list,
  filteredTests,
  activeSummaryFilters,
  graphView,
  colorCodes,
  currentUser,
  selectedLanguage,
  t,
) {
  if (!activeOrder) {
    return null;
  }
  
  const summaryFilterCodes =
    activeSummaryFilters && activeSummaryFilters?.length > 0
      ? activeSummaryFilters.map((f) => f.filters).flat()
      : [];

  const categoryWithinSummaryFilters = (group) => {
    return group.results.some((r) => summaryFilterCodes.includes(r.code));
  };

  // if (activeOrder.groups.length > 0) {
  const groups = filteredTests ? filteredTests.groups : activeOrder.groups;
  const isManuallyCompleted = filteredTests ? filteredTests.manually_completed : activeOrder.manually_completed;

  return (
    <div className="child-margins-y-2" id="resultCategories">
      {groups.map((group) => {
        if (
          (filteredTests &&
            (!group.filteredCodes || group.filteredCodes.length === 0)) ||
          (activeSummaryFilters.length > 0 &&
            !categoryWithinSummaryFilters(group))
        )
          return null;
        return (
          <ResultCategory
            key={group.id}
            graphView={graphView}
            list={list}
            orderNumber={activeOrder.order_number}
            group={group}
            currentUser={currentUser}
            colorCodes={colorCodes}
            filtered={!!filteredTests}
            summaryFilterCodes={summaryFilterCodes}
            isManuallyCompleted={isManuallyCompleted}
          />
        );
      })}
      {/* NOTE: WILL AFFECT PUHTI SIDE, NEED TO TEST
        {renderOrderedTests(
          activeOrder,
          filteredTests,
          colorCodes,
          currentUser,
          t
        )} */}
      <NoticeText selectedLanguage={selectedLanguage} />
    </div>
  );
  /* } else {
    return renderOrderedTests(
      activeOrder,
      filteredTests,
      colorCodes,
      currentUser,
      t
    );
  }*/
}

function renderOrderedTests(
  activeOrder,
  filteredTests,
  colorCodes,
  currentUser,
  t,
) {
  if (activeOrder.ordered_tests && activeOrder.ordered_tests.length > 0) {
    let tests = activeOrder.ordered_tests;

    if (
      filteredTests &&
      filteredTests.ordered_tests &&
      filteredTests.ordered_tests.length
    ) {
      tests = filteredTests.ordered_tests;
      let numberOfFilteredTests = 0;
      filteredTests.ordered_tests.forEach((test) => {
        if (test.filtered) numberOfFilteredTests += 1;
      });

      if (numberOfFilteredTests === 0) return null;
    }
    return (
      <div>
        <div className="row">
          <div className="col-xs-12 col-l-8">
            <div className="margin-1 margin-bottom">
              <h2 className="size-large weight-500 color-purple margin-0-25 margin-bottom">
                {t("render_ordered_tests.waiting_for_results")}
              </h2>
            </div>
          </div>
        </div>

        <div className="child-margins-y-1">
          {tests.map((test) => {
            if (filteredTests && !test.filtered) return null;
            return (
              <TestResult
                key={test.id}
                pending
                currentUser={currentUser}
                orderNumber={activeOrder.order_number}
                {...test}
                resultColor={
                  colorCodes
                    ? colorCodes[test.description.id % colorCodes.length]
                    : "#39235d"
                }
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Home);
