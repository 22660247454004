import React from "react";
import { Link } from "react-router-dom";
import Logo from "./Logo.js";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="padding-1 padding-y">
      <div className="container">
        <div className="row center-xs">
          <div className="col-xs-12 col-s-8 col-m-6">
            <div className="logo margin-1 margin-bottom">
              <Logo />
            </div>
            <div className="card padding-1">
              <div className="child-margins-y-0-5 padding-1 padding-y text-align-center">
                <h1 className="size-medium weight-500 color-purple">
                  {t("not_found.page_not_found")}
                </h1>
                <p>{t("not_found.page_you_requested_not_found")}.</p>
                <p>
                  <Link to="/" className="link">
                    {t("not_found.back_to_main_page")}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NotFound;
