import React from "react";
import { format } from "date-fns";
import blood from "../assets/blood.svg";
import clock from "../assets/clock.svg";
import env from "../env";

import Select, { components } from "react-select";
import SVG from "react-inlinesvg";
import { useTranslation } from "react-i18next";
import { renderActiveOrderActionButtons } from "./ResultSummary/ResultSummaryCard";
const OrderSelector = (props) => {
  const { t } = useTranslation();

  const {
    orders,
    selectStyles,
    setFilteredTests,
    setOrderChangeSpinner,
    history,
    children,
  } = props;

  const bloodtestOptions =
    orders?.state.list && orders.state.list.length > 0
      ? orders.state.list.map((order) => {
          let time = order.order_time;
          if (order.sampling_time) {
            time = order.sampling_time;
          }
          let label = order.sampling_time
            ? t("order_selector.test_results")
            : t("order_selector.order");
          let timestamp = format(time, "D.M.YYYY");
          const icon = order.sampling_time ? blood : clock;
          return {
            value: order.order_number,
            label: `${label} – ${timestamp}`,
            type: "bloodtest",
            typeLabel: t("order_selector.blood_sample"),
            icon: icon,
          };
        })
      : [];

  const options = bloodtestOptions;

  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div className="select__icon">
          <SVG src={props.data.icon} />
        </div>
        <span className="weight-700 margin-0-5 margin-right mobile-hide">
          {props.data.typeLabel}
        </span>
        <span>{props.data.label}</span>
        <span className="margin-0-5 margin-left weight-700">
          #{props.data.value}
        </span>
      </components.Option>
    );
  };

  const SingleValue = (props) => {
    return (
      <components.SingleValue {...props}>
        <div className="select__icon">
          <SVG src={props.selectProps.value.icon} />
        </div>
        <span className="weight-700 margin-0-5 margin-right mobile-hide">
          {props.selectProps.value.typeLabel}
        </span>
        <span>{props.selectProps.value.label}</span>
        <span className="margin-0-5 margin-left weight-700">
          #{props.data.value}
        </span>
      </components.SingleValue>
    );
  };

  const getOrderSelectValue = () => {
    if (
      props.bloodTestEnabled &&
      props.match.path === "/orders/:id" &&
      orders.state.active
    ) {
      return options.find(
        (option) => option.value === orders.state.active.order_number
      );
    }
    return null;
  };

  const onOrderSelectChange = (event) => {
    const orderId = event.value;
    const order = orders.state.list.find(
      (candidate) => candidate.order_number === orderId
    );

    if (order && event.type === "bloodtest" && props.bloodTestEnabled) {
      history.push(`/orders/${order.order_number}`);
    }

    setFilteredTests(null);
    setOrderChangeSpinner(true);
  };

  return (
    <>
      <h2 className="size-medium weight-500 margin-0-5 margin-left">
        {t("order_selector.choose_order")}
      </h2>
      <Select
        id="orderSelect"
        options={options}
        components={{ Option, SingleValue }}
        isSearchable={false}
        value={getOrderSelectValue()}
        styles={selectStyles}
        onChange={(event) => onOrderSelectChange(event)}
      />
      {env.APP_ID === "vital" &&
        renderActiveOrderActionButtons(orders.state.active?.order_number, t)}
      <div className="margin-1 margin-top">{children}</div>
    </>
  );
};

export default OrderSelector;
