import React from "react";
import styles from "./NewSuggestionItem.module.scss";

import SuggestionLink from "../SuggestionLink/SuggestionLink";
import { MarkdownRender } from "../Markdown/MarkdownRender";

const NewSuggestionItem = ({ title, image, content, link, linkText }) => {
  return (
    <div className="col-xs-12 col-m-6 flex-y">
      <div className="suggestion-item card">
        <div
          className={styles.header}
          {...(image &&
            image.length > 0 && {
              style: { backgroundImage: `url(${image})` },
            })}
        >
          {image && image.length > 0 && (
            <div className={styles.headerGradient} />
          )}
          {title && title.length > 0 && (
            <h2
              className={
                image && image.length > 0
                  ? styles.itemTitleWhite
                  : styles.itemTitle
              }
            >
              {title}
            </h2>
          )}
        </div>
        <div className="suggestion-item__main">
          <MarkdownRender markdown={content.replace("\n", "  \n\r")} />
        </div>
        {link && link.length > 0 && (
          <SuggestionLink link={link} linkText={linkText} />
        )}
      </div>
    </div>
  );
};

export default NewSuggestionItem;
