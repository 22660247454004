import React, { useEffect, useState } from "react";
import axios from "axios";
import { Subscribe } from "unstated";
import { getColorCodes } from "./../theme/colorCoding";

import LoadingSpinner from "./../components/LoadingSpinner";
import SuggestionSection from "./../components/SuggestionSection";
import MicrobiomeSuggestionSection from "./../components/MicrobiomeSuggestionSection";
import OrdersContainer from "../containers/OrdersContainer";
import MicrobiomeOrdersContainer from "../containers/MicrobiomeOrdersContainer";

import ErrorBoundary from "./../components/ErrorBoundary";
import { withTranslation, useTranslation } from "react-i18next";

const Promotions = (props) => {
  const [spinner, setSpinner] = useState(false);
  const [colorCodes, setColorCodes] = useState();
  const [currentUser, setCurrentUser] = useState();
  const { t } = useTranslation();
  const selectedLanguage = props.i18n?.language;

  useEffect(() => {
    function restoreScroll() {
      if (props.location.state && props.location.state.scrollY) {
        window.scrollTo(0, props.location.state.scrollY);
      }
    }

    fetchData();
    restoreScroll();
    const codes = getColorCodes();
    setColorCodes(codes);
  }, [props.location.state]);

  useEffect(() => {
    if (spinner) {
      window.setTimeout(() => {
        setSpinner(false);
      }, 1000);
    }
  }, [spinner]);

  function fetchData() {
    axios
      .get("/api/user")
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <ErrorBoundary>
      <Subscribe to={[OrdersContainer, MicrobiomeOrdersContainer]}>
        {(orders, microbiomeOrders) => {
          return (
            <React.Fragment>
              <div className="header">
                <div className="container">
                  <h1 className="weight-500 size-huge color-purple margin-1 margin-bottom">
                    {t("promotions.promotions_and_services")}
                  </h1>
                </div>
              </div>
              <div className="padding-2 padding-top margin-0-75 margin-bottom">
                <div className="container">
                  <div className="row">
                    <div className="col-xs-12">
                      {!spinner ? (
                        <>
                          <SuggestionSection
                            identityNumber={currentUser?.identity_number}
                            activeOrder={orders.state.active}
                            orders={orders.state.list}
                            colorCodes={colorCodes}
                            withBottomSectionStyling={false}
                            hideTestSuggestions
                            requireActiveOrder={false}
                            selectedLanguage={selectedLanguage}
                          />

                          {props.microbiomeTestEnabled &&
                            props.match.path === "/microbiome_orders/:id" &&
                            microbiomeOrders.state.active &&
                            microbiomeOrders.state.list &&
                            microbiomeOrders.state.list.length > 0 && (
                              <MicrobiomeSuggestionSection />
                            )}
                        </>
                      ) : (
                        <LoadingSpinner />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        }}
      </Subscribe>
    </ErrorBoundary>
  );
};

export default withTranslation()(Promotions);
