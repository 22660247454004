import React, { Component } from "react";
import classNames from "classnames";
import NumericInput from "react-numeric-input";

class NumberInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isBeingEdited: true,
      value: this.props.value,
    };
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.submitChange = this.submitChange.bind(this);
  }

  onFocus(event) {
    this.setState({
      isBeingEdited: true,
    });
  }

  onBlur(event) {
    const { value } = this.props;

    let updatedValue = value;

    if (isNaN(value) || value === null) {
      updatedValue = this.props.min;
    } else if (value < this.props.min) {
      updatedValue = this.props.min;
    } else if (value > this.props.max) {
      updatedValue = this.props.max;
    }

    if (updatedValue % 1 !== 0) {
      updatedValue = updatedValue.toFixed(0);
    }

    this.props.onChange(updatedValue, this.props.name);
  }

  onChange(value) {
    this.props.onChange(value, this.props.name);
  }

  cancelEdit() {
    this.setState({
      isBeingEdited: false,
      value: this.props.value,
    });
  }

  submitChange() {
    this.props.onChange(this.state.value, this.props.name);
    this.setState({
      isBeingEdited: false,
    });
  }

  renderLabel() {
    if (this.props.label && this.props.label.length > 0) {
      return (
        <label htmlFor={this.props.name} className="number-input__label">
          {this.props.label}
        </label>
      );
    }
  }

  render() {
    const { label, suffix, onChange, showStepper, ...inputProps } = this.props;

    const wrapperClasses = classNames("number-input", {
      "number-input--active":
        this.state.isBeingEdited && this.props.showStepper,
    });

    return (
      <div className={wrapperClasses}>
        {this.renderLabel()}
        <NumericInput
          {...inputProps}
          id={this.props.name}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onClick={this.onFocus}
          mobile
          min={this.props.min}
          max={this.props.max}
          style={null}
          value={this.props.value}
          className="number-input__field"
          step={1}
          format={(value) => value.replace(".", ",") + `${this.props.suffix}`}
          parse={(formattedValue) =>
            formattedValue.replace(`${this.props.suffix}`, "").replace(",", ".")
          }
        />
      </div>
    );
  }
}

NumberInput.defaultProps = {
  autoComplete: "false",
  type: "text",
  label: "",
  hint: "",
  error: "",
  placeholder: " ",
  disabled: false,
  className: "",
  name: "",
  min: 0,
  max: 500,
  value: "",
  showStepper: false,
  onBlur: () => {},
  onFocus: () => {},
  onChange: () => {},
};

export default NumberInput;
