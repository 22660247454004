import { ReviewContext } from "./ReviewProvider";
import React, { useContext, useState, Fragment } from "react";
import format from "date-fns/format";
import RangeChart from "../RangeChart";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { resultIsWithinRange } from "../../helpers.js";
const DoctorResultCard = (props) => {
  let {
    readOnly = false,
    category,
    answers = [],
    description = {},
    previous_results,
  } = props;
  const { t } = useTranslation();
  const {
    title,
    test,
    recommended_value_enabled,
    recommended_value_max,
    recommended_value_min,
    name,
  } = description;

  //for testing

  /*
  previous_results = [
    {
      last_changed: "Sat, 12 Mar 2022 11:56:22.000000000 UTC +00:00",
      value: "0.40",
      unit: "",
      reference_value_text: ">80",
      reference_value_low: 0.4,
      reference_value_high: 0.12e1,
    },
    {
      //
      last_changed: "Thu, 28 Apr 2022 00:29:00.000000000 UTC +00:00",
      value: "0,4",
      unit: "",
      reference_value_text: ">80",
      reference_value_low: null,
      reference_value_high: 0.1e1,
    },
  ];
  */

  const { handleSelectionsChange } = useContext(ReviewContext);
  const [option, setOption] = useState(null);

  const formatOptionLabel = (props) => {
    const { label, value, grade } = props;
    return (
      <div style={{ zIndex: 9 }}>
        <div
          className={
            grade === "good"
              ? "color-green"
              : grade === "bad"
              ? "color-red"
              : "color-orange"
          }
          style={{ fontWeight: 600, position: "relative", top: 5 }}
        >
          {label}
        </div>
        <div>{value}</div>
      </div>
    );
  };

  const colourStyles = {
    singleValue: (styles, { data }) => ({ ...styles, height: 50 }),
    control: (styles, { data }) => ({ ...styles, backgroundColor: "#e2ecf6" }),
    menuPortal: (provided) => ({ ...provided, zIndex: 3 }),
    menu: (provided) => ({ ...provided, zIndex: 3 }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "50px",
      backgroundColor: "#f3f7fb",
    }),
  };

  const renderResultCardHeader = () => {
    const getResultValueColor = (resultWithinRange) => {
      return resultWithinRange ? "color-green" : "color-red";
    };

    const renderRecentTestsString = () => {
      if (previous_results.length > 1) {
        return (
          <h3 className="weight-500 size-small color-purple text-right">
            {t("doctor_review.previous_results")}:{" "}
            {previous_results.map((result, i) => {
              const {
                last_changed,
                value,
                unit,
                reference_value_high,
                reference_value_low,
              } = result;

              const resultWithinRange = resultIsWithinRange(
                value,
                reference_value_low,
                reference_value_high,
              );

              return (
                <Fragment key={format(last_changed, "D.MM")}>
                  <span className={getResultValueColor(resultWithinRange)}>
                    {value}
                    {unit !== "0" && unit}
                  </span>
                  <span>
                    {" "}
                    {format(last_changed, "D.MM")}
                    {i + 1 !== previous_results.length && ","}{" "}
                  </span>
                </Fragment>
              );
            })}
          </h3>
        );
      }
      return (
        <h3 className="weight-500 size-small color-purple text-right">
          {t("doctor_review.no_previous_result")}
        </h3>
      );
    };

    return (
      <div className="result-card-title-wrapper">
        <h1 className="weight-500 size-medium color-purple flex-1">
          {name ? title + " (" + name + ")" : title}
        </h1>
        <div style={{ maxWidth: "75%" }}>
          {test && renderRecentTestsString()}
        </div>
      </div>
    );
  };

  const formattedAnswers = answers
    ?.map((answer) => {
      answer.attributes.id = answer.id;
      return answer.attributes;
    })
    .sort((a, b) => (a.position > b.position ? 1 : -1));

  return (
    <div className="card margin-1 padding margin-bottom">
      {renderResultCardHeader()}
      <div className="margin-6 padding-1">
        {props.value && (
          <RangeChart
            value={props.value}
            unit={props.unit}
            referenceLow={props.reference_value_low}
            referenceHigh={props.reference_value_high}
            recommendationLow={
              recommended_value_enabled &&
              recommended_value_min &&
              recommended_value_min > 0
                ? recommended_value_min
                : null
            }
            recommendationHigh={
              recommended_value_enabled &&
              recommended_value_max &&
              recommended_value_max > 0
                ? recommended_value_max
                : null
            }
            hideText
          />
        )}
        {!readOnly && formattedAnswers && formattedAnswers.length > 0 && (
          <Select
            id="orderSelect"
            options={formattedAnswers}
            value={option}
            formatOptionLabel={formatOptionLabel}
            isSearchable={false}
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            styles={colourStyles}
            onChange={(option) => {
              setOption(option);

              handleSelectionsChange(category, option, answers, () => {
                setOption(option);
              });
            }}
          />
        )}
      </div>
    </div>
  );
};
export default DoctorResultCard;
