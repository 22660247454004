import React from "react";
import env from "../env";

import Navigation from "../components/navigation/Navigation";
import NavLink from "../components/navigation/NavLink";
import NavDropdownLink from "../components/navigation/NavDropdownLink";
import {
  Activity,
  Globe,
  User,
  LogOut,
  Archive,
  Users,
  Tag,
} from "react-feather";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18n from "../localization/i18n";
import { Flipper } from "../flipper";

import Footer from "../components/Footer";
import checkIcon from "../assets/check.svg";
import SVG from "react-inlinesvg";

const changeLanguage = (lng) => {
  i18n.changeLanguage(lng);
  localStorage.setItem("selectedLanguage", lng);
  window.location.reload(false);
};

const availableLanguages = () => {
  const languageString = env.LOCALIZATION_ALLOWED_LOCALES || "";
  const languages = languageString.split(",").map((lang) => lang.trim());
  return languages;
};

const current_language = i18n.language;

const handleLogOut = (event, csrfParam, csrfToken, url, redirectUrl) => {
  event.preventDefault();

  let params = {};
  params[csrfParam] = csrfToken;

  axios
    .delete(url, {
      params: params,
      maxRedirects: 0,
    })
    .then((response) => {
      window.location = redirectUrl + "?logout";
    })
    .catch((error) => {
      window.location = redirectUrl + "?logout";
      console.error(error);
    });
};

const LeftLinks = (props) => {
  const { company, microbiomeTestEnabled } = props;
  const promotionsEnabled = env.PROMOTIONS_ENABLED === "true";
  const profileDisabled = env.PROFILE_DISABLED === "true";
  const { t } = useTranslation();

  if (company) {
    return (
      <>
        <NavLink
          to="/corporate"
          label={t("main_layout.whole_picture")}
          icon={<Activity />}
        />
      </>
    );
  } else {
    return (
      <>
        <NavLink
          to="/"
          label={t("order_selector.test_results")}
          icon={<Activity />}
        />
        {!profileDisabled && (
          <NavLink to="/profile" label={t("profile.profile")} icon={<User />} />
        )}
        {microbiomeTestEnabled && (
          <NavLink
            to="/aktivoi"
            label="Aktivoi mikrobiomitesti"
            icon={<Archive />}
          />
        )}
        {promotionsEnabled && (
          <NavLink
            to="/promotions"
            icon={<Tag />}
            label={t("promotions.promotions_and_services")}
          />
        )}
      </>
    );
  }
};

const check_icon = <SVG src={checkIcon} />;
const filler_icon = <SVG src={checkIcon} className={"nav-list__filler-icon"} />;

const RightLinks = (props) => {
  const { company, csrfParam, csrfToken } = props;
  const { t } = useTranslation();
  const localeSwitcherEnabled = Flipper.enabled("localeSwitcher");

  if (company) {
    return (
      <div className="nav-list__inner">
        <NavLink
          to="/company_users/sign_out"
          label={t("main_layout.sign_out")}
          icon={<LogOut />}
          onClick={(event) =>
            handleLogOut(
              event,
              csrfParam,
              csrfToken,
              "/company_users/sign_out",
              "/corporate",
            )
          }
        />
      </div>
    );
  } else {
    return (
      <div className="nav-list__inner">
        {localeSwitcherEnabled && availableLanguages().length > 1 && (
          <NavDropdownLink
            label={t("main_layout.language")}
            icon={<Globe />}
            children={availableLanguages().map((lang) => (
              <NavLink
                onClick={() => changeLanguage(lang)}
                to={"/"}
                key={lang}
                label={t("languages." + lang)}
                icon={lang === current_language ? check_icon : filler_icon}
                menuitem={true}
              />
            ))}
          />
        )}
        <NavLink
          to="/users/sign_out"
          label={t("main_layout.sign_out")}
          icon={<LogOut />}
          onClick={(event) =>
            handleLogOut(event, csrfParam, csrfToken, "/users/sign_out", "/")
          }
        />
        <NavLink
          to={t("main_navigation.store_link")}
          href={t("main_navigation.store_link")}
          label={t("main_layout.back_to_store")}
        />
      </div>
    );
  }
};

export const MainLayout = (props) => {
  return (
    <div className="site-wrapper">
      <div className="site-wrapper__main">
        <Navigation
          company={props.company}
          leftLinks={
            <LeftLinks
              company={props.company}
              microbiomeTestEnabled={props.microbiomeTestEnabled}
            />
          }
          rightLinks={
            <RightLinks
              company={props.company}
              csrfParam={props.csrfParam}
              csrfToken={props.csrfToken}
            />
          }
        />
        {props.children}
      </div>
      <div className="site-wrapper__footer">
        <Footer />
      </div>
    </div>
  );
};
export default MainLayout;
