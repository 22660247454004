/* eslint no-console:0 */
import "core-js/fn/array/flat-map";
import WebpackerReact from "webpacker-react";

import "./app.scss";

import App from "../src/containers/App.js";
import Login from "../src/containers/Login.js";
import LoginTeliaProviders from "../src/containers/LoginTeliaProviders.js";
import LoginGrandId from "../src/containers/LoginGrandId.js";
import Maintenance from "../src/containers/Maintenance.js";

WebpackerReact.setup({
  App,
  Login,
  LoginTeliaProviders,
  LoginGrandId,
  Maintenance,
});
