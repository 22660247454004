import { format } from "date-fns";

import env from "./env";

export const stageEnv = {
  development: env.ENVIRONMENT === "development",
  staging: env.ENVIRONMENT === "staging",
  production: env.ENVIRONMENT === "production",
};

export const formatStringDate = (date) => {
  return format(new Date(date), "D.M.YYYY");
};

export const resultIsWithinRange = (
  value,
  reference_value_low,
  reference_value_high,
) => {
  value = value.replace(",", ".");
  value = value.replace(">", "");
  value = value.replace("<", "");

  if (!reference_value_low) {
    return parseFloat(value) <= parseFloat(reference_value_high);
  }
  if (!reference_value_high) {
    return parseFloat(value) >= parseFloat(reference_value_low);
  } else
    return (
      parseFloat(value) >= parseFloat(reference_value_low) &&
      parseFloat(value) <= parseFloat(reference_value_high)
    );
};

export const parseDisplayValue = (displayValue, t) => {
  if (displayValue[0] === "<" || displayValue[0] === ">") {
    return displayValue[0] === "<"
      ? t("linechart.less_than") + " " + displayValue.replace("<", "")
      : t("linechart.more_than") + " " + displayValue.replace(">", "");
  }
  return displayValue;
};

export const calculateValues = (
  passedValue,
  passedReferenceLow,
  passedReferenceHigh,
  passedMinValue,
) => {
  const { displayValue, value } = parseValueToFloat(passedValue);

  let referenceLow;
  let referenceHigh;

  let lowestValue;
  let highestValue;
  let maxWidth;

  let hideLow = false;
  let hideUpper = false;

  if (passedReferenceLow && passedReferenceHigh) {
    // If there is both lower and upper referencevalues we set them as is and
    // then set lowestValue and highestValue 25% percent to each direction so
    // it we have some red bars also. If the actual value is more than 25%
    // off from reference values then calculate the low and high using value.

    //miika: ^that 25% percentage was actually 30%? anyways was causing issues so changed to 0.1 on lines 61 and 62
    referenceLow = parseFloat(passedReferenceLow);
    referenceHigh = parseFloat(passedReferenceHigh);

    maxWidth = (referenceHigh - referenceLow) / 0.4;
    lowestValue = referenceLow - maxWidth * 0.1; //0.3;
    highestValue = referenceHigh + maxWidth * 0.1; //0.3;
    if (lowestValue < 0) {
      lowestValue = 0;
      highestValue = referenceHigh + referenceLow;
    }
    if (highestValue === referenceHigh) {
      highestValue = referenceHigh + maxWidth * 0.4;
    }
    if (lowestValue > value) {
      lowestValue = value * 0.75;
    }
    if (highestValue < value) {
      highestValue = value * 1.25;
    }
  } else if (passedReferenceLow) {
    referenceLow = parseFloat(passedReferenceLow);
    maxWidth = referenceLow / 0.5;
    lowestValue = referenceLow - maxWidth * 0.5;
    highestValue = referenceLow + maxWidth * 0.5;
    referenceHigh = referenceLow + maxWidth * 0.5;
    hideUpper = true;
    if (value <= referenceLow) {
      if (referenceLow === 0) {
        highestValue = 1;
      }
    }
    if (highestValue * 0.95 < value) {
      highestValue = value * 1.25;
      referenceHigh = value * 1.25;
    }
  } else if (passedReferenceHigh) {
    referenceHigh = parseFloat(passedReferenceHigh);
    maxWidth = referenceHigh / 0.5;
    lowestValue = referenceHigh - maxWidth * 0.5;
    highestValue = referenceHigh + maxWidth * 0.5;
    referenceLow = referenceHigh - maxWidth * 0.5;
    hideLow = true;
    if (highestValue * 0.95 < value) {
      highestValue = value * 1.25;
    }
  } else if (!passedReferenceHigh && !passedReferenceLow) {
    referenceHigh = null;
    referenceLow = null;
    lowestValue = passedMinValue ? parseFloat(passedMinValue) * 0.75 : 0;
    highestValue = value * 1.25;
    hideLow = true;
    hideUpper = true;
  }

  return {
    value,
    displayValue,
    referenceLow,
    referenceHigh,
    lowestValue,
    highestValue,
    hideLow,
    hideUpper,
  };
};

export const parseValueToFloat = (passedValue) => {
  if (!passedValue) {
    return {
      displayValue: 0,
      value: 0,
    };
  }

  const displayValue = passedValue.replace(".", ",");

  let value = parseFloat(
    displayValue.replace(",", ".").replace(/[^\d.-]/g, ""),
  );

  // if (/^</.test(passedValue)) {
  //   value = value * 0.9;
  // } else if (/^>/.test(passedValue)) {
  //   value = value * 1.1;
  // }

  return { displayValue, value };
};

export const valueHasSmallerThanSign = (value) => {
  return /^</.test(value);
};

export const valueHasLargerThanSign = (value) => {
  return /^>/.test(value);
};

export const getUserAgeInYears = (identityNumber) => {
  const day = identityNumber.slice(0, 2);
  const month = identityNumber.slice(2, 4);
  let year = identityNumber.slice(4, 6);
  const century = identityNumber.slice(6, 7);
  if (century === "A") {
    year = 2000 + year;
  } else {
    year = 1900 + year;
  }
  const birthday = new Date(year, month, day);
  const difference = Date.now() - birthday.getTime();
  const ageDate = new Date(difference);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const getUserSex = (identityNumber) => {
  // Sex determining digit offset based on identity number format
  const sexDeterminingDigitOffset = 2;
  const sexDeterminingDigit = parseInt(
    identityNumber.charAt(identityNumber.length - sexDeterminingDigitOffset),
    10,
  );

  if (sexDeterminingDigit % 2 === 0) {
    return "female";
  } else {
    return "male";
  }
};

export const shouldShowFerritDisclaimer = (
  code,
  identityNumber,
  lastChanged,
) => {
  const mehilainenDateThreshold = new Date("2021-03-25");

  if (mehilainenDateThreshold > new Date(lastChanged)) {
    if (code === "1395") {
      return true;
    }
  } else if (
    code === "1395" &&
    getUserAgeInYears(identityNumber) >= 16 &&
    getUserSex(identityNumber) === "female"
  ) {
    return true;
  }
};

// Check if we should hide the rangechart for a test since
// the reference values vary by user information we don't have
export const shouldChartBeHidden = (code, identityNumber) => {
  const hideChartForTheseTests = ["2502", "1366", "1422"];
  if (
    hideChartForTheseTests.includes(code) &&
    getUserSex(identityNumber) === "male"
  ) {
    return true;
  } else {
    return false;
  }
};

export const generateMicrobiomeChartData = (group, bacteria) => {
  let totalValue = 0;
  let othersPercentage = 0;
  let data = [];

  group.bacteria.forEach((bacterium) => {
    const bacteriumValues = bacteria.find(
      (candidate) =>
        candidate &&
        candidate.microbiome_bacterium &&
        candidate.microbiome_bacterium.id &&
        bacterium.id &&
        candidate.microbiome_bacterium.id === bacterium.id,
    );
    if (bacteriumValues) {
      totalValue += parseFloat(bacteriumValues.result_value);
    }
  });

  group.bacteria.forEach((bacterium) => {
    const bacteriumValues = bacteria.find(
      (candidate) =>
        candidate &&
        candidate.microbiome_bacterium &&
        candidate.microbiome_bacterium.id &&
        bacterium.id &&
        candidate.microbiome_bacterium.id === bacterium.id,
    );
    if (bacteriumValues) {
      const percentage =
        (parseFloat(bacteriumValues.result_value) / totalValue) * 100;

      if (percentage >= 1) {
        data.push({
          id: bacterium.id,
          label: bacterium.heading,
          value: Number(percentage.toFixed(0)),
        });
      } else {
        othersPercentage += percentage;
      }
    }
  });

  let sortedData = data.sort((a, b) => b.value - a.value);

  if (othersPercentage > 0) {
    sortedData.push({
      id: "muut",
      label: "Muut",
      value: Number(othersPercentage.toFixed(0)),
    });
  }

  return sortedData;
};

export function hasNumber(myString) {
  return /\d/.test(myString);
}

export const checkResultForFastingStatements = (testResultCode) => {
  const fastingExceptionStatements = [];

  switch (testResultCode) {
    case "2770":
      fastingExceptionStatements.push(
        "Jos olet paastonnut ennen näytteenottoa, viitearvo on alle 1,7 mmol/l. Jos et ole paastonnut, viitearvo on alle 2 mmol/l.",
      );
      break;
    case "2099":
      fastingExceptionStatements.push(
        "Jos olet paastonnut ennen näytteenottoa, viitearvo on alle 3 mmol/l. Jos et ole paastonnut, viitearvo on alle 2,8 mmol/l.",
      );
      break;
    case "2095":
      fastingExceptionStatements.push(
        "Jos olet paastonnut ennen näytteenottoa, viitearvo on alle 5 mmol/l. Jos et ole paastonnut, viitearvo on alle 4,8 mmol/l.",
      );
      break;
    default:
  }

  return fastingExceptionStatements;
};

export const getIronTest45292PositiveOrNegativeMessage = ({
  groupResults,
  secondIronTestValue,
}) => {
  const firstIronTestValue = groupResults?.find(
    (result) => result.code === "4529",
  )?.value; // Iron levels on the first test before supplement. Rauta ennen rautalisän ottamista
  const ferritinValue = groupResults?.find(
    (result) => result.code === "1395",
  )?.value;
  const difference = secondIronTestValue - firstIronTestValue;

  if (!ferritinValue || !firstIronTestValue) {
    return { positive: null, negative: null };
  }

  if (ferritinValue < 20 && difference > 18) {
    return { positive: "Rautalisä imeytyy" };
  }
  if (ferritinValue >= 20 && ferritinValue <= 60 && difference > 10) {
    return { positive: "Rautalisä imeytyy" };
  }
  if (ferritinValue > 60 && difference > 3) {
    return { positive: "Rautalisä imeytyy" };
  }

  return { negative: "Rautalisä ei imeydy riittävästi" };
  // Ferrit lähtötaso <20 ja Fe muutos 2h jälkeen verrattuna ensimmäiseen mittaukseen >18. = Imeytyy
  // Ferrit lähtötaso 20-60 ja Fe muutos 2h jälkeen verrattuna ensimmäiseen mittaukseen >10. = Imeytyy
  // Ferrit lähtötaso >60 ja Fe muutos 2h jälkeen  verrattuna ensimmäiseen mittaukseen >3. = Imeytyy
};
