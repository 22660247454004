import React, { Component } from "react";

// Components
import TestResult from "./../components/TestResult";

class ResultCategory extends Component {
  setResultColor(result) {
    const id = result.description?.id;
    if (this.props.isManuallyCompleted && result.pending) {
      return "#D8DBE3";
    }
    else {
      if (this.props.colorCodes && this.props.colorCodes.length > 0) {
        return this.props.colorCodes[id % this.props.colorCodes.length];
      }
    }
  };

  setCardColor(result) {
    if (this.props.isManuallyCompleted && result.pending) {
      return "#F4F7FA";
    }
  }

  render() {
    const { group, list, graphView } = this.props;
    return (
      <div>
        <div className="row">
          <div className="col-xs-12 col-l-8">
            <div className="margin-1 margin-bottom">
              <h2 className="size-large weight-500 color-purple margin-0-25 margin-bottom">
                {group.name}
              </h2>
              <p>{group.description}</p>
            </div>
          </div>
        </div>
        <div className="child-margins-y-1">
          {group.results?.length > 0 &&
            group.results.map((result, index) => {
              if (
                (this.props.filtered && !result.filtered) ||
                (this.props.summaryFilterCodes.length &&
                  !this.props.summaryFilterCodes.includes(result.code))
              )
                return null;
              return (
                <TestResult
                  key={index}
                  {...result}
                  list={list}
                  isBundleTest={result.description?.batch_test}
                  group={group}
                  graphView={graphView}
                  currentUser={this.props.currentUser}
                  orderNumber={this.props.orderNumber}
                  resultColor={this.setResultColor(result)}
                  cardColor={this.setCardColor(result)}
                  isManuallyCompleted={this.props.isManuallyCompleted}
                />
              );
            })}
        </div>
      </div>
    );
  }
}

export default ResultCategory;
