const cache = {};
const cacheMeta = {};
export const AppCache = {
  transformKey: (key) => {
    if (Array.isArray(key)) {
      return key.join("-");
    }
    return key;
  },
  get: (key) => {
    return cache[AppCache.transformKey(key)];
  },
  set: (key, value) => {
    cache[AppCache.transformKey(key)] = value;
    cacheMeta[AppCache.transformKey(key)] = {
      timestamp: new Date(),
    };
    return value;
  },
};
