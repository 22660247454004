import React, { Component } from "react";
import SVG from "react-inlinesvg";

// Assets
import logoSymbol from "../assets/logo-symbol.svg";
import { useTranslation } from "react-i18next";

const LoadingSpinner = ({ small, noText, text }) => {
  const { t } = useTranslation();
  return (
    <div className="loading-spinner">
      <SVG
        src={logoSymbol}
        className={
          small
            ? "loading-spinner__logo loading-spinner__logo--small"
            : "loading-spinner__logo"
        }
      />
      {!noText && (
        <div className="loading-spinner__text">
          {text ? text : t("loading_spinner.loading")}
        </div>
      )}
    </div>
  );
};

export default LoadingSpinner;
