import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import { useTranslation } from "react-i18next";
const DoctorReviewDoctorAssigner = ({
  setReviewer,
  orderId,
  selectedDoctor,
}) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState(
    selectedDoctor ? selectedDoctor : null,
  );
  const [doctors, setDoctors] = useState(null);

  const formatOptionLabel = ({ attributes }) => {
    return (
      <div>
        {attributes && (
          <div
            style={{
              margin: -12,

              opacity: 0.5,
              padding: 12,
            }}
          >
            {attributes.first_name + " " + attributes.last_name}
          </div>
        )}
      </div>
    );
  };

  const assignReviewer = async (option) => {
    await axios.put(
      "/doctor/api/commerce_orders/" + orderId + "/doctor_review",
      {
        doctor_review: { doctor_id: option.id },
      },
    );
  };

  useEffect(() => {
    axios
      .get(
        "/doctor/api/commerce_orders/" +
          orderId +
          "/doctor_review/available_doctors",
      )
      .then(({ data }) => {
        setDoctors(data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [orderId]);

  return (
    <div className="review-assigner-container">
      <h3 className="weight-500 size-small color-purple">
        {t("doctor_review.assigned_doctor")}
      </h3>
      {doctors && (
        <Select
          id="orderSelect"
          defaultValue={doctors.find((d) => d.id === selectedDoctor.id)}
          options={doctors}
          value={selected}
          formatOptionLabel={formatOptionLabel}
          isSearchable={false}
          getOptionValue={(option) => option.id} // changes here!!!
          onChange={(option) => {
            setReviewer(option);
            setSelected(option);
            assignReviewer(option);
          }}
        />
      )}
    </div>
  );
};

export default DoctorReviewDoctorAssigner;
