import React from "react";
import styles from "./SuggestionLink.module.scss";
import classNames from "classnames";
import { ExternalLink } from "react-feather";

const SuggestionLink = ({ link, linkText, filled, underline = true }) => {
  const itemLinkStyles = classNames(styles.itemLink, {
    [styles.filled]: filled,
    [styles.underline]: underline,
  });

  return (
    <div className={styles.itemLinkContainer}>
      <a
        className={itemLinkStyles}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>{linkText && linkText.length > 0 ? linkText : link}</span>
        <ExternalLink />
      </a>
    </div>
  );
};

export default SuggestionLink;
