import React, { useEffect, useState } from "react";
import axios from "axios";
import { Info } from "react-feather";

const NoticeText = ({ text, selectedLanguage }) => {
  const [noticeText, setNoticeText] = useState(text);

  useEffect(() => {
    if (!text) {
      axios
        .get(`/api/notice_banner?language=${selectedLanguage}`)
        .then((response) => {
          const text = response.data;

          if (text.length > 0) {
            setNoticeText(text);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [selectedLanguage]);

  return (
    <>
      {noticeText ? (
        <div className="notice margin-1 margin-top">
          <div className="notice__icon">
            <Info />
          </div>
          <div
            className="notice__content"
            dangerouslySetInnerHTML={{ __html: noticeText }}
          />
        </div>
      ) : null}
    </>
  );
};

export default NoticeText;
