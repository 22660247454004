import React from "react";

const MicrobiomeSuggestionSection = () => {
  return (
    <section className="bottom-section">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <h2 className="size-medium color-purple weight-500 margin-1 margin-bottom">
              Ohjeet ovat yleisiä vinkkejä, joilla pystyt vaikuttamaan suoliston
              mikrobiomiin. Huomioi myös mahdollisia sairauksia (kuten IBS tai
              keliakia) koskevat erityisruokavaliot.
            </h2>
            <div className="suggestion-item card">
              <div className="suggestion-item__main">
                <h3 className="size-large semibold color-purple margin-0-5 margin-bottom">
                  Ruokavalion ja elintapojen ohjeet
                </h3>
                <div className="microbiome-suggestion-section">
                  <p>
                    Suoliston bakteeristoon voi vaikuttaa itse, vaikka myös
                    ympäristöllä on suuri rooli mikrobiston muodostumisessa.
                    Erityisesti ruokavalion avulla pystymme itse vaikuttamaan
                    suoliston mikrobistoon. Kuidut ja probiootit ovat
                    avainasemassa.
                  </p>
                  <ul>
                    Lisää näitä:
                    <li>
                      runsaskuituiset kasvikset, kaikki vihannekset, hedelmät,
                      juurekset ja marjat
                    </li>
                    <li>täysjyvätuotteet, esimerkiksi täysjyväkaura</li>
                    <li>
                      muista syödä kasviksia kaikissa väreissä (polyfenolit)
                    </li>
                    <li>fermentoidut (eli hapatetut) ruoat kuten hapankaali</li>
                    <li>
                      probioottiset maitotuotteet kuten piimä, kefiiri, juusto,
                      viili ja probioottiset jogurtit
                    </li>
                    <li>
                      kuitupitoiset ruoat ja tarvittaessa kuituvalmisteet kuten
                      psyllium tai pellavansiemenet
                    </li>
                  </ul>
                  <ul>
                    Vältä näitä:
                    <li>runsaasti punaista lihaa sisältävä ruokavalio</li>
                    <li>runsas valkoinen sokeri, karkit, pullat, keksit yms</li>
                    <li>runsasrasvaiset pikaruoat ja einekset</li>
                    <li>transrasvoja sisältävät ruoat</li>
                    <li>liikaa proteiinia sisältävä ruokavalio</li>
                    <li>turhat antibioottikuurit</li>
                    <li>liiallinen stressi ja vähäinen palautuminen</li>
                    <li>runsas alkoholinkäyttö ja tupakointi</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="suggestion-item card">
              <div className="suggestion-item__main">
                <h3 className="size-large semibold color-purple margin-0-5 margin-bottom">
                  Kuidut (prebiootit)
                </h3>
                <div className="microbiome-suggestion-section">
                  <p>
                    Prebiooteilla tarkoitetaan sulamattomia kuituja, joita
                    suolistomikrobit käyttävät ruokanaan. Prebiootteja saadaan
                    esimerkiksi kuitupitoisista kasviksista, joilla on toki myös
                    muita etuja terveyden kannalta. Myös muut kasvikunnan
                    tuotteet kuten sienet, palkokasvit ja täysjyväviljat
                    toimivat prebiootteina.
                  </p>
                  <p>
                    Suomalaiset saavat ruokavaliostaan keskimäärin liian vähän
                    kuitua. Tämä koskee sekä naisia että miehiä. Kuidun riittävä
                    saanti voi tulla ongelmaksi muun muassa
                    vähähiilihydraattisella, ketogeenisella tai gluteenittomalla
                    ruokavaliolla. Riittävä kuidun saanti vaikuttaa terveyteen
                    kokonaisvaltaisesti; kuidulla on vaikutusta myös esimerkiksi
                    verisuonisairauksien, ylipainon ja aikuisiän diabeteksen
                    riskissä ja hoidossa.
                  </p>
                  <p>
                    Kuitua voidaan yleisesti ottaen sanoa olevan kahdenlaista:
                    liukenematonta ja liukoista kuitua, mutta todellisuudessa
                    jako ei ole näin karkea. On myös hyvä opetella tutkailemaan
                    ruokien ravintokuidun osuutta elintarvikkeiden
                    tuoteselosteista. Kuidunkin suhteen kasvikunnan tuotteiden
                    mahdollisimman monipuolinen käyttö on suotavaa.
                  </p>
                  <ul>
                    Hyviä prebioottisia ruokia ovat esimerkiksi:
                    <li>artisokka, maa-artisokka, parsa, purjo</li>
                    <li>pavut, kikherneet ja linssit </li>
                    <li>marjat ja hedelmät</li>
                    <li>sipulit, valkosipuli</li>
                    <li>täysjyväkaura ja muut täysjyvätuotteet</li>
                    <li>
                      keitetty ja jäähdytetty peruna, bataatti ja riisi,
                      tuorepuurot, kuumentamattomat kaurahiutaleet, banaani
                      (resistentti tärkkelys)
                    </li>
                  </ul>
                  <p>
                    Kuidun määrää voi lisätä syömällä enemmän kaikkia
                    kasvikunnan tuotteita kuten papuja ja linssejä sekä
                    täysjyvää. Lisäksi voi ottaa käyttöön itselle sopivan
                    kuituvalmisteen. Kuituvalmisteita on useita erilaisia.
                    Kuituvalmisteen valinnassa tulee huomioida myös muut
                    suoliston sairaudet kuten IBS (kaikki valmisteet eivät sovi
                    kaikille).
                  </p>
                  <p>
                    Kuitulisän käytön aloittaminen auttaa vatsaa myös toimimaan
                    useammin. On normaalia, että vatsa toimii myös useammin kuin
                    kerran päivässä. Saman voi havaita lisätessään ruokavalioon
                    runsaasti kasviperäistä ruokaa. Kuitulisää käytettäessä
                    tulee nauttia runsaasti nestettä, ja mielellään liottaa
                    kuituvalmiste jo etukäteen nesteeseen.
                  </p>
                  <p>
                    Kaikkien muidenkin kasvisten ja marjojen syönti runsain
                    määrin on suotavaa. Nauti marjoja ja kasviksia kaikissa
                    väreissä. Huolehdi, että päivän aikana saat ruokavaliostasi
                    viittä-kuutta eri väriä erilaisista kasviksista ja
                    marjoista. Värikästä ruokaa suosiessasi saat runsasti
                    polyfenoleita. Karkeasti sanottuna polyfenolit tarkoittavat
                    kasvien väriaineita. Esimerkiksi kotimaisissa marjoissa on
                    runsaasti polyfenoleita. Polyfenoleja saadaan kasvisten ja
                    marjojen lisäksi myös esimerkiksi kahvista, teestä ja
                    kylmäpuristetuista öljyistä.
                  </p>
                </div>
              </div>
            </div>

            <div className="suggestion-item card">
              <div className="suggestion-item__main">
                <h3 className="size-large semibold color-purple margin-0-5 margin-bottom">
                  Probiootit
                </h3>
                <div className="microbiome-suggestion-section">
                  <p>
                    Probiootti tarkoittaa elävää bakteerikantaa, jolla on
                    todettuja vaikutuksia suolistoon. Probioottisia ruokia
                    kannattaa nauttia säännöllisesti; näin vaikutamme suoliston
                    hyvinvointiin arkisilla valinnoillamme. Probiootteja voi
                    nauttia myös ravintolisinä eli “maitohappobakteereina”.
                  </p>
                  <p>
                    Probiootteja voi nauttia erityisesti ulkomaan matkojen ja
                    antibioottikuurin aikana, sekä mikäli suoliston bakteeristo
                    ei ole tasapainossa. Tietyistä probiooteista voi olla apua
                    myös oireiden ja sairauksien ehkäisyssä ja hoidossa; asiasta
                    tehdään tällä hetkellä lisää tutkimusta.
                  </p>
                  <p>
                    Probioottiset lisäravinteet sisältävät useimmiten
                    Bifidobacterium-, Lactobacillus-, Lactococcus- ja
                    Streptococcus-sukujen lajeja. Probioottien teho vaihtelee
                    riippuen ihmisestä ja käytetystä valmisteesta.
                  </p>
                  <p>
                    Probioottisia valmisteita on monenlaisia, eikä oikean
                    valmisteen löytäminen ole aina helppoa. Kannattaa kiinnittää
                    huomiota valmisteen sisältämiin eri bakteerikantoihin.
                    Niillä on kaikilla erilaiset vaikutukset suoliston
                    mikrobistoon ja terveyteen. Valmisteissa on myös eroja
                    lajien monipuolisuudessa sekä bakteerien määrissä.
                    Yhdistämällä eri bakteerikantoja saadaan usein parhaiten
                    tasapainotettua suolistoflooraa.
                  </p>
                  <p>
                    Probioottisten lisäravinteiden vaikutuksista terveyteen on
                    vielä melko vähän tutkimusta. Niiden käytöstä voi olla
                    joissain tapauksissa apua. Tärkeämpää on kuitenkin muuttaa
                    ruokavaliota entistä monipuolisemmaksi ja
                    kasvisvoittoiseksi, sekä lisätä kuitujen määrää runsaasti.
                  </p>
                  <p>
                    Probioottiset ruoat ovat ruokia, joista saadaan
                    probiootteja. Lisäravinteiden ohella kannattaa erityisesti
                    nauttia päivittäin ruokia, joista saadaan luontaisesti
                    probiootteja.
                  </p>
                  <ul>
                    Probioottisia (probiootteja sisältäviä) ruokia ovat
                    esimerkiksi:
                    <li>
                      fermentoidut eli hapatetut ruoat kuten hapankaali ja
                      hapankurkku
                    </li>
                    <li>
                      tietyt maitotuotteet kuten piimä, kefiiri, juusto, viili
                      ja probioottiset jogurtit.
                    </li>
                  </ul>
                  <p>
                    Kuumuus pilaa bakteerit ja valmisteet. Se kannattaa
                    huomioida, jos esimerkiksi haluaa nauttia hyviä bakteereita
                    hapankaalin tai muiden ruokien kautta. Näitä ruokia ei siis
                    kannata kuumentaa. Säilytä myös probioottiset lisäravinteet
                    viileässä.
                  </p>
                </div>
              </div>
            </div>

            <div className="suggestion-item card">
              <div className="suggestion-item__main">
                <h3 className="size-large semibold color-purple margin-0-5 margin-bottom">
                  Erityisruokavaliot
                </h3>
                <div className="microbiome-suggestion-section">
                  <p>
                    Ruokavalio on aina yksilöllinen. Suolistolle sopivat ruoat
                    ovat yksilöllisiä, eikä oireita aiheuttavia ruokia ole
                    tarkoituksenmukaista syödä, vaikka niillä olisi muille
                    henkilöille suotuisia vaikutuksia. Huomioi ruokavalion
                    suunnittelussa aina myös muut sairautesi ja kysy tarkempaa
                    neuvoa lääkäriltä tai ravitsemusterapeutilta, mikäli sinulla
                    on muita sairauksia.
                  </p>
                  <p>
                    Mikäli kärsit suoliston sairauksista, esimerkiksi IBS:stä,
                    voi olla, että nämä kaikki näissä ohjeissa mainitut ruoat
                    eivät sovi sinulle. Tutustu FODMAP-ruokavalioon, mikäli
                    sinulla on ärtyvän suolen oireyhtymän oireita.
                  </p>
                  <p>
                    Vuotavan suolen yhteydessä on tärkeää hoitaa perussairaudet
                    kuntoon ja vahvistaa suoliston mikrobistoa esimerkiksi
                    probiooteilla. Vuotavan suolen oireyhtymän kohdalla tutkimus
                    on vielä puutteellista, mutta voit toki jo kokeilla
                    itsehoitokeinoja ja erilaisia ruokavalioita, jotta saat apua
                    oireiisiisi. AIP-ruokavalio voi olla hyödyllinen vuotavan
                    suolen oireyhtymän hoidossa.
                  </p>
                  <p>Vältä myös ruokia, joista saat allergiaoireita.</p>
                  <p>
                    Keliakian hoidossa ainoa hoitomuoto on gluteeniton
                    ruokavalio, vaikka tutkimusnäyttöä olisikin yhteyksistä
                    tiettyihin suolistomikrobeihin. Mikäli epäilet keliakiaa,
                    käy keliakiatestissä ja ota yhteyttä lääkäriin. Puhdin
                    kautta pääset myös keliakian tutkimukseen.
                  </p>
                  <p>
                    Laktoosi-intoleranssin hoidossa tulee välttää laktoosia
                    sisältäviä ruokia, eli laktoosia sisältäviä maitotuotteita.
                    Useista maitotuotteista on olemassa laktoositon vaihtoehto.
                    Mikäli epäilet laktoosi-intoleranssia, käy testissä ja ota
                    yhteyttä lääkäriin. Puhdin kautta pääset myös
                    laktoosi-intoleranssin tutkimukseen.
                  </p>
                </div>
              </div>
            </div>

            <div className="suggestion-item card">
              <div className="suggestion-item__main">
                <h3 className="size-large semibold color-purple margin-0-5 margin-bottom">
                  Probiootit
                </h3>
                <div className="microbiome-suggestion-section">
                  <p>
                    Probiootti tarkoittaa elävää bakteerikantaa, jolla on
                    todettuja vaikutuksia suolistoon. Probioottisia ruokia
                    kannattaa nauttia säännöllisesti; näin vaikutamme suoliston
                    hyvinvointiin arkisilla valinnoillamme. Probiootteja voi
                    nauttia myös ravintolisinä eli “maitohappobakteereina”.
                  </p>
                  <p>
                    Probiootteja voi nauttia erityisesti ulkomaan matkojen ja
                    antibioottikuurin aikana, sekä mikäli suoliston bakteeristo
                    ei ole tasapainossa. Tietyistä probiooteista voi olla apua
                    myös oireiden ja sairauksien ehkäisyssä ja hoidossa; asiasta
                    tehdään tällä hetkellä lisää tutkimusta.
                  </p>
                  <p>
                    Probioottiset lisäravinteet sisältävät useimmiten
                    Bifidobacterium-, Lactobacillus-, Lactococcus- ja
                    Streptococcus-sukujen lajeja. Probioottien teho vaihtelee
                    riippuen ihmisestä ja käytetystä valmisteesta.
                  </p>
                  <p>
                    Probioottisia valmisteita on monenlaisia, eikä oikean
                    valmisteen löytäminen ole aina helppoa. Kannattaa kiinnittää
                    huomiota valmisteen sisältämiin eri bakteerikantoihin.
                    Niillä on kaikilla erilaiset vaikutukset suoliston
                    mikrobistoon ja terveyteen. Valmisteissa on myös eroja
                    lajien monipuolisuudessa sekä bakteerien määrissä.
                    Yhdistämällä eri bakteerikantoja saadaan usein parhaiten
                    tasapainotettua suolistoflooraa.
                  </p>
                  <p>
                    Probioottisten lisäravinteiden vaikutuksista terveyteen on
                    vielä melko vähän tutkimusta. Niiden käytöstä voi olla
                    joissain tapauksissa apua. Tärkeämpää on kuitenkin muuttaa
                    ruokavaliota entistä monipuolisemmaksi ja
                    kasvisvoittoiseksi, sekä lisätä kuitujen määrää runsaasti.
                  </p>
                  <p>
                    Probioottiset ruoat ovat ruokia, joista saadaan
                    probiootteja. Lisäravinteiden ohella kannattaa erityisesti
                    nauttia päivittäin ruokia, joista saadaan luontaisesti
                    probiootteja.
                  </p>
                  <ul>
                    Probioottisia (probiootteja sisältäviä) ruokia ovat
                    esimerkiksi:
                    <li>
                      fermentoidut eli hapatetut ruoat kuten hapankaali ja
                      hapankurkku
                    </li>
                    <li>
                      tietyt maitotuotteet kuten piimä, kefiiri, juusto, viili
                      ja probioottiset jogurtit.
                    </li>
                  </ul>
                  <p>
                    Kuumuus pilaa bakteerit ja valmisteet. Se kannattaa
                    huomioida, jos esimerkiksi haluaa nauttia hyviä bakteereita
                    hapankaalin tai muiden ruokien kautta. Näitä ruokia ei siis
                    kannata kuumentaa. Säilytä myös probioottiset lisäravinteet
                    viileässä.
                  </p>
                </div>
              </div>
            </div>

            <div className="suggestion-item card">
              <div className="suggestion-item__main">
                <h3 className="size-large semibold color-purple margin-0-5 margin-bottom">
                  Muut elintavat ja ympäristö
                </h3>
                <div className="microbiome-suggestion-section">
                  <p>
                    Probioottien ja ruokavalion lisäksi suoliston mikrobistoon
                    voi vaikuttaa myös muilla tavoin kuten stressinhallinnalla,
                    lisäämällä liikuntaa ja liikkumalla luonnossa. Ympäristöllä
                    on suuri merkitys suolistomikrobiomin kannalta. Luonnossa
                    oleilu ja hyville bakteereille luonnostaan altistuminen on
                    suositeltavaa.
                  </p>
                  <p>
                    Stressi voi vaikuttaa negatiivisesti suoliston mikrobistoon.
                    Stressi myös pahentaa hyvin usein muun muassa ärtyvän suolen
                    oireyhtymän oireita. Palautumista voi edistää nukkumalla
                    riittävästi ja harjoittamalla stressinhallintaa.
                  </p>
                  <p>
                    Runsas alkoholin käyttö vaikuttaa epäsuotuisasti suoliston
                    hyvinvointiin. Jatkuva alkoholinkäyttö muuttaa
                    mikrobiflooraa ja aiheuttaa sen epätasapainotilaa tuhoamalla
                    erityisesti hyviä suoliston mikrobilajeja.
                  </p>
                </div>
              </div>
            </div>

            <div className="suggestion-item card">
              <div className="suggestion-item__main">
                <h3 className="size-large semibold color-purple margin-0-5 margin-bottom">
                  Antibiootit ja tietyt lääkkeet heikentävät suoliston
                  normaaliflooraa
                </h3>
                <div className="microbiome-suggestion-section">
                  <p>
                    Antibiootit ovat kiistatta tietyissä tapauksissa hyödyksi.
                    Liiallinen antibioottien käyttö ja turhaan syödyt useat
                    antibioottikuurit ovat kuitenkin voineet vaatia veronsa;
                    suoliston mikrobisto kärsii antibioottien syömisestä.
                    Antibiootit tuhoavat nimittäin samalla myös osan suoliston
                    hyvää mikrobistoa.
                  </p>
                  <p>
                    Syötyjen antibioottikuurien vaikutus suolistolle voi olla
                    useita vuosia. Myös jo lapsuuden aikana syödyillä
                    antibioottikuureilla on merkitystä. Lisäksi antibiooteilla
                    kasvatettu ravinto vaikuttaa syödyn ruoan välityksellä
                    ihmisen suolistomikrobistoon.
                  </p>
                  <p>
                    Myös muilla lääkkeillä kuin antibiooteilla (esimerkiksi
                    tulehduskipulääkkeet) on vaikutusta suoliston mikrobiomiin.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MicrobiomeSuggestionSection;
