import React from "react";
import SVG from "react-inlinesvg";

import env from "../env";

import puhtiLogoWhite from "../assets/puhti-logo-white.svg";
import vitalLogoWhite from "../assets/vital-logo-white.svg";

import puhtiLogoType from "../assets/puhti-logotype.svg";
import vitalLogoType from "../assets/vital-logotype.svg";

import vitalLogo from "../assets/vital-logo.svg";
import puhtiLogo from "../assets/puhti-logo.svg";

import logoSymbol from "../assets/logo-symbol.svg";

const Logo = ({ className, logoType }) => {
  const logo = {
    puhti: {
      noLogo: puhtiLogoType,
      white: puhtiLogoWhite,
      symbol: logoSymbol,
      default: puhtiLogo,
    },
    vital: {
      noLogo: vitalLogoType,
      white: vitalLogoWhite,
      symbol: logoSymbol,
      default: vitalLogo,
    },
  }[env.APP_ID][logoType || "default"];

  return <SVG src={logo} className={className ? className : ""} />;
};

export default Logo;
