import React from "react";
import { Archive, CheckCircle } from "react-feather";

const ActivationSuccess = () => {
  return (
    <div className="padding-2 padding-y">
      <div className="container">
        <div className="row center-m">
          <div className="col-xs-12 col-m-10">
            <div className="card">
              <div className="padding-1 flex-y align-center justify-center">
                <div className="padding-1 padding-top">
                  <CheckCircle className="width-4 height-4 color-green" />
                </div>
                <h1 className="size-large weight-500 color-green margin-1 margin-bottom">
                  Aktivointi onnistui!
                </h1>
              </div>
              <div className="activate__bottom activate__bottom--center">
                <a
                  href="/aktivoi"
                  className="button button--icon button--orange"
                  onClick={() => this.location.reload()}
                >
                  <Archive className="width-1 height-1 margin-0-25 margin-right" />
                  Aktivoi uusi testi
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivationSuccess;
