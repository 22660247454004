import React from "react";

// Assets
import { AlertCircle } from "react-feather";
import { useTranslation } from "react-i18next";
const ErrorMessage = ({ text, callback, callbackText }) => {
  const { t } = useTranslation();
  return (
    <div className="error-message">
      <AlertCircle />
      <div className="error-message__text">
        {text ? text : t("error_message.something_went_wrong")}
      </div>
      {callback && (
        <button onClick={callback} className="button">
          {callbackText ? callbackText : t("error_message.try_again")}
        </button>
      )}
    </div>
  );
};

export default ErrorMessage;
