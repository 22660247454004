import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import styles from "./Markdown.module.scss";

const renderers = {
  listItem: (props) => {
    if (props.ordered) {
      return (
        <>
          <li className={styles.orderedListItem}>
            <span className={styles.orderedListItemIndex}>
              {props.index + 1}
            </span>
            <span className={styles.listItemValue}>{props.children[0]}</span>
          </li>
          {props.children[1] ? (
            <ol className={styles.nestedList}>{props.children[1]}</ol>
          ) : null}
        </>
      );
    } else {
      return (
        <>
          <li className={styles.listItem}>{props.children[0]}</li>
          {props.children[1] ? (
            <ul className={styles.nestedList}>{props.children[1]}</ul>
          ) : null}
        </>
      );
    }
  },
};

export const MarkdownRender = ({ markdown }) => {
  return (
    <div className={styles.renderedMarkdown}>
      <ReactMarkdown
        components={renderers}
        remarkPlugins={[remarkGfm]}
        linkTarget="_blank"
      >
        {markdown}
      </ReactMarkdown>
    </div>
  );
};
