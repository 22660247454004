import React, { useState, useEffect } from "react";
import axios from "axios";
import { formatStringDate } from "../helpers";
import { useTranslation } from "react-i18next";
import { MarkdownRender } from "./Markdown/MarkdownRender";
import styles from "../styles/components/_user-doctor-review.scss";

const UserDoctorReview = ({
  orderNumber,
  showFull = false,
  reviewData = null,
}) => {
  const { t } = useTranslation();
  const [toggled, setToggled] = useState(showFull);
  const [error, setError] = useState();
  const [review, setReview] = useState(reviewData);

  useEffect(() => {
    if (reviewData) {
      setReview(reviewData);
    } else {
      let path = `/api/orders/${orderNumber}/doctor_review`;
      // if (lang) { path = `${path}?locale=${lang}`; }
      axios
        .get(path)
        .then((response) => {
          setReview(response.data);
        })
        .catch((error) => {
          setError(error.error);
        });
    }
  }, [reviewData, orderNumber, setError, setReview]);

  return (
    <div
      className={
        !toggled
          ? "card padding-1 max-height-helper margin-1 margin-bottom"
          : "card padding-1 margin-1 margin-bottom"
      }
    >
      <h1 className="size-medium weight-500 color-purple ">
        {t("doctor_review.doctor_review")}
      </h1>
      {review && (
        <>
          <p>{formatStringDate(review.published_at)}</p>
          <p className="margin-1 margin-bottom">
            {t("doctor_review.reviewed_by")}: {review.doctor.name},{" "}
            {review.doctor.professional_title}
          </p>

          <div className="margin-bottom margin-1 special">
            <MarkdownRender
              markdown={review.statement.split("\n").join("  \n\r")}
            />
            <div
              className={
                !toggled ? "show-more-container shadow" : "show-more-container"
              }
            >
              <a
                href="."
                className="show-more-text"
                style={{ cursor: "pointer" }}
                onClick={(event) => {
                  event.preventDefault();
                  setToggled(!toggled);
                }}
              >
                {!toggled
                  ? t("doctor_review.show_more") + "..."
                  : t("doctor_review.show_less")}
              </a>
            </div>
          </div>
        </>
      )}
      {error && (
        <>
          <p className="margin-1 margin-bottom">{error}</p>
        </>
      )}
    </div>
  );
};

export default UserDoctorReview;
