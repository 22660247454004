import React, { Component } from "react";

import EmptyLayout from "../layouts/EmptyLayout";
import LoginFooter from "../components/LoginFooter";
import ErrorBoundary from "../components/ErrorBoundary";
import Logo from "../components/Logo";
import { withTranslation } from "react-i18next";

// Assets
import SVG from "react-inlinesvg";

import logoMobiilivarmenne from "../../images/auth-logos/mpki.telia.1.svg";
import logoAktia from "../../images/auth-logos/oidc.aktia.1.svg";
import logoAlandsbanken from "../../images/auth-logos/oidc.alandsbanken.1.svg";
import logoDanskebank from "../../images/auth-logos/oidc.danskebank.1.svg";
import logoHandlesbanken from "../../images/auth-logos/oidc.handelsbanken.1.svg";
import logoNordea from "../../images/auth-logos/oidc.nordea.1.svg";
import logoOmasp from "../../images/auth-logos/oidc.omasp.1.svg";
import logoPop from "../../images/auth-logos/oidc.pop.1.svg";
import logoSp from "../../images/auth-logos/oidc.sp.1.svg";
import logoSpankki from "../../images/auth-logos/oidc.spankki.1.svg";
import logoOP from "../../images/auth-logos/saml.op.1.svg";
import { ArrowLeft } from "react-feather";

class LoginAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <ErrorBoundary>
        <EmptyLayout>
          <div className="padding-1">
            <div className="auth-card">
              <div className="auth-card__top bg-purple">
                <Logo logoType="white" className={`auth-card__logo`} />
              </div>
              <div className="auth-card__content">
                <h1 className="margin-0-25 margin-bottom size-medium weight-500 color-purple">
                  {t("login.select_authentication_provider")}
                </h1>

                <div className="auth-methods">
                  <AuthMethod
                    logo={logoMobiilivarmenne}
                    name="mpki.telia.1"
                    title="Mobiilivarmenne"
                  />
                  <AuthMethod
                    logo={logoAktia}
                    name="oidc.aktia.1"
                    title="Aktia"
                  />
                  <AuthMethod
                    logo={logoAlandsbanken}
                    name="oidc.alandsbanken.1"
                    title="Ålandsbanken"
                  />
                  <AuthMethod
                    logo={logoDanskebank}
                    name="oidc.danskebank.1"
                    title="Danske Bank"
                  />
                  <AuthMethod
                    logo={logoHandlesbanken}
                    name="oidc.handelsbanken.1"
                    title="Handelsbanken"
                  />
                  <AuthMethod
                    logo={logoNordea}
                    name="oidc.nordea.1"
                    title="Nordea"
                  />
                  <AuthMethod
                    logo={logoOmasp}
                    name="oidc.omasp.1"
                    title="Oma Säästöpankki"
                  />
                  <AuthMethod
                    logo={logoPop}
                    name="oidc.pop.1"
                    title="POP Pankki"
                  />
                  <AuthMethod
                    logo={logoSp}
                    name="oidc.sp.1"
                    title="Säästöpankki"
                  />
                  <AuthMethod
                    logo={logoSpankki}
                    name="oidc.spankki.1"
                    title="S-Pankki"
                  />
                  <AuthMethod logo={logoOP} name="saml.op.1" title="OP" />
                </div>
                <div className="flex margin-1 margin-top">
                  <a className="inline-icon-text color-blue-dark" href="/">
                    <ArrowLeft className="inline-icon-text__icon" />
                    <span className="inline-icon-text__text">
                      {t("login.back_to_sign_in")}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <LoginFooter />
        </EmptyLayout>
      </ErrorBoundary>
    );
  }
}

const AuthMethod = ({ logo, name, title }) => {
  return (
    <div className="auth-methods__item">
      <a
        href={`/users/auth/openid_connect?acr_values=${name}`}
        className="auth-method-link"
      >
        <SVG className="auth-method-logo" src={logo} />
        <h3 className="auth-method-title">{title}</h3>
      </a>
    </div>
  );
};

export default withTranslation()(LoginAuth);
