import React from "react";
import styles from "./OrderChangeSpinner.module.scss";
import LoadingSpinner from "../LoadingSpinner";

import classNames from "classnames";

const OrderChangeSpinner = ({ visible }) => {
  const spinnerClassNames = classNames(styles.spinner, {
    [styles.visible]: visible,
  });

  return (
    <div className={spinnerClassNames}>
      <LoadingSpinner small noText />
    </div>
  );
};

export default OrderChangeSpinner;
