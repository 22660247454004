import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";

import {
  calculateValues,
  parseValueToFloat,
  shouldShowFerritDisclaimer,
} from "../helpers";

// Components
import RangeChart from "../components/RangeChart";
import LineChart from "../components/LineChart";
import LoadingSpinner from "./../components/LoadingSpinner";
import ErrorMessage from "./../components/ErrorMessage";
import ErrorBoundary from "./../components/ErrorBoundary";
import SuggestionSection from "./../components/SuggestionSection";
import { MarkdownRender } from "../components/Markdown/MarkdownRender";

function BackToResultsLink(props) {
  return (
    <Link
      to={{
        pathname: `/orders/${props.orderId}`,
        state: { scrollY: props.scrollY },
      }}
      className="arrow-link arrow-link--reverse"
    >
      Takaisin tuloksiin
    </Link>
  );
}

class Single extends Component {
  componentDidMount() {
    this.fetchData();
  }

  state = {
    loading: true,
  };

  fetchData() {
    this.setState({ error: null, loading: true }, () => {
      axios
        .get(`/api/results/${this.props.match.params.id}`)
        .then((response) => {
          const results = response.data.results.sort(function (a, b) {
            return new Date(b.sampling_time) - new Date(a.sampling_time);
          });

          this.setState({
            loading: false,
            results: response.data.results,
            activeResult: results[0],
            description: response.data.description,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
            error: error,
          });
          console.error(error);
        });
    });
  }

  renderStatements() {
    const { activeResult } = this.state;
    if (activeResult.statements && activeResult.statements.length > 0) {
      return (
        <div className="margin-0-5 margin-top">
          <div className="result-box__label">Lausunto</div>
          <div className="child-margins-y-0-5">
            {activeResult.statements.map((statement, index) => {
              statement.replace(/(?:\r\n|\r|\n)/g, "\n");
              if (statement === "Katso lausunto") return null;
              return (
                <p key={"s" + index}>
                  {statement.split("\n").map((chunk, index) => {
                    return (
                      <span key={"c" + index}>
                        {chunk}
                        <br />
                      </span>
                    );
                  })}
                </p>
              );
            })}
          </div>
        </div>
      );
    }
  }

  renderValue() {
    const { activeResult } = this.state;
    const { currentUser } = this.props;
    if (activeResult.reference_value_low || activeResult.reference_value_high) {
      return (
        <div>
          <div className="result-box__chart">
            <RangeChart
              value={activeResult.value}
              unit={activeResult.unit}
              recommendationLow={
                shouldShowFerritDisclaimer(
                  activeResult.code,
                  currentUser.identity_number,
                ) && 30
              }
              referenceLow={activeResult.reference_value_low}
              referenceHigh={activeResult.reference_value_high}
            />
          </div>
          {this.renderStatements()}
        </div>
      );
    } else {
      return (
        <div>
          <div className="result-box__label">Tulos</div>
          <div
            className={`${activeResult.value.length < 30 ? "size-medium" : ""}`}
          >
            {activeResult.value} {activeResult.unit}
          </div>
          {this.renderStatements()}
        </div>
      );
    }
  }

  renderLineChart() {
    const { results, activeResult } = this.state;
    const { currentUser } = this.props;

    let maxValue = results[0].value;

    if (results.length > 1) {
      maxValue = results
        .map((result) => result.value)
        .reduce((prev, current) => {
          return parseFloat(current) > parseFloat(prev) ? current : prev;
        });
    }

    const {
      referenceLow,
      referenceHigh,
      lowestValue,
      highestValue,
      hideLow,
      hideUpper,
    } = calculateValues(
      maxValue,
      results[0].reference_value_low,
      results[0].reference_value_high,
    );

    if (results[0].reference_value_low || results[0].reference_value_high) {
      return (
        <div className="card">
          <div className="padding-1-5 padding-bottom-0">
            <h2 className="weight-500 size-large color-purple margin-0-5 margin-bottom">
              Muutos
              {results.length === 1 && (
                <div className="size-normal weight-400">
                  Seuraavat tulokset päivittyvät tähän kuvaajaan. Voit helposti
                  seurata tulosten kehittymistä.
                </div>
              )}
            </h2>
          </div>
          <div className="linechart-container">
            <div className="linechart-container__inner">
              <LineChart
                data={results.map((result) => ({
                  date: new Date(result.sampling_time),
                  value: parseValueToFloat(result.value).value,
                  displayValue: parseValueToFloat(result.value).displayValue,
                }))}
                unit={results[0].unit}
                min={lowestValue}
                max={highestValue}
                minR={referenceLow}
                maxR={referenceHigh}
                hideMinR={hideLow}
                hideMaxR={hideUpper}
                oneResult={results.length === 1}
                recommendationLow={
                  shouldShowFerritDisclaimer(
                    activeResult.code,
                    currentUser.identity_number,
                  ) && 30
                }
              />
            </div>
          </div>
        </div>
      );
    }
  }

  renderSources() {
    const { description } = this.state;
    if (description.source_urls && description.source_urls.length > 0) {
      const urls = description.source_urls;
      return (
        <div className="margin-1 margin-top">
          Lähteet:{" "}
          {urls.map((url, index) => {
            return (
              <React.Fragment key={index}>
                <a
                  href={url}
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Lähde {index + 1}
                </a>{" "}
              </React.Fragment>
            );
          })}
        </div>
      );
    }
  }

  renderReadMore() {
    const { description } = this.state;
    if (description.article_url) {
      return (
        <div className="margin-1 margin-top">
          <a
            href={description.article_url}
            className="button"
            target="_blank"
            rel="noopener noreferrer"
          >
            Lisätietoa aiheesta
          </a>
        </div>
      );
    }
  }

  renderReferenceDisclaimer() {
    const { activeResult } = this.state;

    const { value, referenceLow, referenceHigh } = calculateValues(
      activeResult.value,
      activeResult.reference_value_low,
      activeResult.reference_value_high,
    );

    if (!(referenceHigh || referenceLow)) {
      return "";
    } else if (
      isNaN(value) ||
      (value >= referenceLow && value <= referenceHigh)
    ) {
      return "";
    } else {
      return (
        <div className="card padding-1-5">
          <div className="child-margins-y-0-5">
            <p>
              Tuloksesi on viitearvojen ulkopuolella. Jos epäilet sairautta, ota
              yhteyttä lääkäriin asian selvittämiseksi. Voit tulostaa raportin
              ja käydä sen läpi yhdessä lääkärin kanssa.
            </p>
          </div>
        </div>
      );
    }
  }
  renderDescription() {
    const { description } = this.state;
    return (
      <div className="padding-2 padding-y">
        <div className="container">
          <div className="row text-align-left">
            <div className="col-xs-12">
              <div className="child-margins-y-1 margin-2 margin-bottom">
                <div className="card padding-1-5">
                  <h2 className="weight-500 color-purple size-large margin-1 margin-bottom">
                    Uusin tulos
                  </h2>
                  {this.renderValue()}
                </div>
                {this.renderReferenceDisclaimer()}
                {this.renderLineChart()}
              </div>
            </div>
            <div className="col-xs-12">
              <div className="card padding-1-5">
                <h2 className="weight-500 size-large color-purple margin-0-5 margin-bottom">
                  Tietoa
                </h2>
                <div className="markdown-content">
                  <MarkdownRender markdown={description.description} />
                </div>
                {this.renderSources()}
                {this.renderReadMore()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { description, loading, error } = this.state;

    if (loading) {
      return (
        <React.Fragment>
          <div className="header">
            <div className="container">
              <div className="row">
                <div className="col-xs-12">
                  <div className="margin-0-25 margin-bottom">
                    <BackToResultsLink
                      orderId={this.props.match.params.orderId}
                      scrollY={
                        this.props.location.state &&
                        this.props.location.state.scrollY
                      }
                    >
                      Takaisin tuloksiin
                    </BackToResultsLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="padding-2 padding-y">
            <div className="container">
              <div className="row">
                <div className="col-xs-12">
                  <LoadingSpinner text="Ladataan testin tietoja" />
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else if (error) {
      return (
        <ErrorBoundary>
          <React.Fragment>
            <div className="header">
              <div className="container">
                <div className="row">
                  <div className="col-xs-12">
                    <div className="margin-0-25 margin-bottom">
                      <BackToResultsLink
                        orderId={this.props.match.params.orderId}
                        scrollY={
                          this.props.location.state &&
                          this.props.location.state.scrollY
                        }
                      >
                        Takaisin tuloksiin
                      </BackToResultsLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="padding-2 padding-y">
              <div className="container">
                <div className="row">
                  <div className="col-xs-12">
                    <ErrorMessage
                      text="Testin tietojen lataus epäonnistui"
                      callback={this.fetchData.bind(this)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        </ErrorBoundary>
      );
    } else {
      return (
        <ErrorBoundary>
          <React.Fragment>
            <div className="header">
              <div className="container">
                <div className="row">
                  <div className="col-xs-12">
                    <div className="margin-0-25 margin-bottom">
                      <BackToResultsLink
                        orderId={this.props.match.params.orderId}
                        scrollY={
                          this.props.location.state &&
                          this.props.location.state.scrollY
                        }
                      >
                        Takaisin tuloksiin
                      </BackToResultsLink>
                    </div>
                    <h1 className="size-huge weight-500 color-purple margin-1 margin-bottom">
                      {description.title} ({description.name})
                    </h1>
                    <div className="stats">
                      <div className="stats__item">
                        <div className="color-gray">Ryhmä</div>
                        <div className="size-large weight-500">
                          {description.group}
                        </div>
                      </div>
                      <div className="stats__item">
                        <div className="color-gray">Uusin tulos</div>
                        <div className="size-large weight-500">
                          {this.state.activeResult &&
                            format(
                              this.state.activeResult.last_changed,
                              "D.MM.YYYY",
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.renderDescription()}
            <SuggestionSection
              activeResultCode={this.state.activeResult.code}
            />
          </React.Fragment>
        </ErrorBoundary>
      );
    }
  }
}
export default Single;
