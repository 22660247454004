const env = require("./env");

const linksMap = {
  puhti: {
    loginFaqUrl: () =>
      `${env.WEBSHOP_URL}/faq-vastaus/ongelmia-kirjautumisessa-oma-puhtiin`,
    orderPageUrl: () => `${env.WEBSHOP_URL}/tuotteet/`,
    repurchaseUrl: ({ ids }) => `${env.WEBSHOP_URL}/tuotteet`,
    /*
    legacy
    repurchaseUrl: ({ ids }) =>
      `${env.WEBSHOP_URL}/ostoskori/?add-to-cart=${ids}`,
      */
    microbiomeTestsInfoUrl: () =>
      `${env.WEBSHOP_URL}/tuote/suoliston-mikrobiomitesti`,
    customOrderPageUrl: () => `${env.WEBSHOP_URL}/tuotteet/mittatilaus`,
  },
  vital: {
    loginFaqUrl: () => `${env.WEBSHOP_URL}/vanliga-fragor`,
    orderPageUrl: () => `${env.WEBSHOP_URL}/halsokontroller`,
    repurchaseUrl: ({ ids }) => `${env.WEBSHOP_URL}/halsokontroller`,
    /*
    
    legacy
    repurchaseUrl: ({ ids }) =>
      `${env.WEBSHOP_URL}/varukorg/?add-to-cart=${ids}`,
      */
    microbiomeTestsInfoUrl: () => `${env.WEBSHOP_URL}`,
    customOrderPageUrl: () => `${env.WEBSHOP_URL}`,
  },
};

module.exports = {
  resolveUrl: (name, params = {}) => linksMap[env.APP_ID][name](params),
};
