export const features = {};
export const Flipper = {
  enabled: (feature) => features[feature] === true,
  setFeatures: (newFeatures) => {
    Object.keys(features).forEach((key) => {
      Reflect.deleteProperty(features, key);
    });
    Object.keys(newFeatures).forEach((key) => {
      features[key] = newFeatures[key];
    });
  },
};
