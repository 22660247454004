import React, { Component } from "react";
import { Info, CheckCircle } from "react-feather";
import { CSSTransition } from "react-transition-group";
import axios from "axios";
import update from "immutability-helper";
import { getUserSex } from "../helpers";

import NumberInput from "../components/NumberInput";
import LoadingSpinner from "./../components/LoadingSpinner";
import ErrorMessage from "./../components/ErrorMessage";
import ErrorBoundary from "./../components/ErrorBoundary";
import Question from "../components/Question";
import {
  QUESTIONS_FI,
  QUESTIONS_SE,
  QUESTIONS_SE_EN,
  MICROBIOME_QUESTIONS,
} from "../data.js";
import { withTranslation } from "react-i18next";
import env, { DEFAULT_LOCALE } from "../env";
import i18n from "../localization/i18n.js";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadingUser: true,
      profileLoaded: false,
      edited: false,
      profileEdited: false,
      userEdited: false,
      error: null,
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.fetchData();
    this.deferProfileUpdatePrompt();
  }

  fetchData() {
    axios
      .get("/api/profile")
      .then((response) => {
        this.setState({
          profileLoaded: true,
          profileData: { ...response.data },
          updatedAt: response.data.updated_at,
        });
        this.getUser();
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: error,
        });
        console.error(error);
      });
  }

  getUser() {
    axios
      .get("/api/user")
      .then((response) => {
        this.setState({
          currentUser: response.data,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: error,
        });
        console.error(error);
      });
  }

  deferProfileUpdatePrompt() {
    axios
      .post("/api/profile/defer")
      .then((response) => {
        console.info("Defer successful", response);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleOnChange(value, name) {
    const oldState = this.state.profileData;
    const newState = update(oldState, { [name]: { $set: value } });
    this.setState({
      profileData: newState,
      edited: true,
      profileEdited: true,
      success: false,
    });
  }

  handleConsentChange(value, name) {
    const oldState = this.state.currentUser;

    const newState = update(oldState, { [name]: { $set: value } });

    this.setState({
      currentUser: newState,
      edited: true,
      userEdited: true,
      success: false,
    });
  }

  handleSubmit() {
    if (this.state.profileEdited) {
      this.setState({ error: null, loading: true }, () => {
        axios
          .put("/api/profile", this.state.profileData)
          .then((response) => {
            if (!this.state.userEdited) {
              this.setState({
                loading: false,
                success: true,
                edited: false,
                profileEdited: false,
                updatedAt: response.data.updated_at,
              });
            } else {
              this.setState({ profileEdited: false });
              this.handleUserUpdate();
            }
          })
          .catch((error) => {
            this.setState({
              loading: false,
              error: error,
            });
            console.error(error);
          });
      });
    } else if (!this.state.profileEdited && this.state.userEdited) {
      this.handleUserUpdate();
    }
  }

  handleUserUpdate() {
    this.setState({ error: null, loading: true }, () => {
      axios
        .put("/api/user", {
          allow_anonymous_data_usage:
            this.state.currentUser["allow_anonymous_data_usage"],
        })
        .then((response) => {
          this.setState({
            loading: false,
            success: true,
            edited: false,
            userEdited: false,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
            error: error,
          });
          console.error(error);
        });
    });
  }

  renderNumberInputs() {
    const { t } = this.props;
    const isPuhti = env.APP_ID === "puhti";

    return (
      <React.Fragment>
        {isPuhti && (
          <h1 className="size-large weight-500 color-purple margin-0-5 margin-bottom">
            {t("profile.basic_info")}
          </h1>
        )}
        <div className="flex-y align-start">
          <div className="card margin-1 margin-bottom padding-1 flex align-center child-margins-x-1">
            <NumberInput
              label={t("profile.weight")}
              suffix="kg"
              name="weight"
              min={20}
              max={500}
              value={this.state.profileData.weight}
              onChange={this.handleOnChange}
              showStepper={false}
            />

            <NumberInput
              label={t("profile.height")}
              suffix="cm"
              name="height"
              min={60}
              max={300}
              value={this.state.profileData.height}
              onChange={this.handleOnChange}
              showStepper={false}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderQuestions(questions, user, t) {
    const userSex = getUserSex(user?.identity_number || "");
    return questions
      .filter((question) => {
        if (question.hasOwnProperty("sex")) {
          return userSex === question.sex;
        } else return true;
      })
      .map((question) => {
        if (question.type === "matrix") {
          return (
            <div key={question.id} className="card padding-1">
              <h2 className="size-medium weight-500 padding-1-5 padding-bottom">
                {question.title}
              </h2>
              <div className="flex wrap responsive-columns negative-margin-0-75">
                {question.questions.map((innerQuestion) => {
                  return (
                    <Question
                      t={t}
                      profileData={this.state.profileData}
                      key={innerQuestion.id}
                      id={innerQuestion.id}
                      title={innerQuestion.title}
                      type={innerQuestion.type}
                      name={innerQuestion.name}
                      options={innerQuestion.options}
                      value={this.state.profileData[innerQuestion.name] || ""}
                      onChange={this.handleOnChange}
                      subQuestion
                    />
                  );
                })}
              </div>
            </div>
          );
        } else {
          return (
            <Question
              t={t}
              profileData={this.state.profileData}
              key={question.id}
              id={question.id}
              title={question.title}
              type={question.type}
              name={question.name}
              options={question.options}
              value={this.state.profileData[question.name] || ""}
              onChange={this.handleOnChange}
            />
          );
        }
      });
  }

  renderSubmitContent() {
    const { t } = this.props;
    if (this.state.success) {
      return (
        <div className="weight-500 color-white size-medium line-1 padding-1 padding-y text-align-center">
          {t("profile.saved")}
        </div>
      );
    } else {
      return (
        <div className="flex align-center justify child-margins-x-0-5 padding-0-5 padding-y">
          <div className="profile-card__notice">
            <Info />
            <h3>
              {this.state.error
                ? t("profile.saving_failed")
                : t("profile.changes_in_profile")}
            </h3>
          </div>
          <button
            onClick={this.handleSubmit}
            className="button"
            disabled={this.state.loading}
          >
            {this.state.loading
              ? t("profile.saving")
              : t("profile.save_changes")}
          </button>
        </div>
      );
    }
  }

  renderUpdatedAtString() {
    const { t } = this.props;

    if (this.state.updatedAt) {
      const dateString =
        t("profile.last_updated") +
        " " +
        new Date(this.state.updatedAt).toLocaleString(DEFAULT_LOCALE, {
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
        });
      return (
        <h1 className="size-normal color-purple margin-0-5 margin-bottom">
          {dateString}
        </h1>
      );
    } else return;
  }

  renderSubmit() {
    return (
      <CSSTransition
        in={this.state.edited}
        timeout={{ enter: 400, exit: 3000 }}
        classNames="profile-footer"
        unmountOnExit
      >
        <div
          className={`profile-footer ${
            this.state.error && "profile-footer-error"
          }`}
        >
          <div className="container">
            <div className="row center-m text-align-left">
              <div className="col-xs-12 col-m-10">
                <div className="relative">
                  <div className="profile-card">
                    {this.renderSubmitContent()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    );
  }

  render() {
    if (
      !this.state.loading &&
      this.state.profileLoaded &&
      this.state.currentUser
    ) {
      const { location, t } = this.props;
      const isPuhti = env.APP_ID === "puhti";
      const lang = i18n.language;
      return (
        <ErrorBoundary>
          <div className="padding-2 padding-y">
            <div className="container">
              <div className="row center-m text-align-left">
                <div className="col-xs-12 col-m-10">
                  {location &&
                    location.state &&
                    location.state.activationSuccess && (
                      <div className="card card--purple flex align-center padding-1 margin-2 margin-bottom animation-fade-top">
                        <CheckCircle className="color-green" />
                        <div className="size-medium margin-0-5 margin-left">
                          <span className="weight-700">
                            {t("profile.activation_successful")}
                          </span>{" "}
                          {t("profile.please_answer_theses_questions")}.
                        </div>
                      </div>
                    )}
                  <div>
                    <h1 className="size-huge weight-500 color-purple margin-1 margin-bottom">
                      {t("profile.profile")}
                    </h1>
                    {this.renderUpdatedAtString()}
                    <h1
                      className="size-medium color-purple margin-0-5 margin-bottom pre-line"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {t("profile.subtitle")}.
                    </h1>
                    {this.renderNumberInputs()}
                  </div>
                  <div>
                    {isPuhti && (
                      <h1 className="size-large weight-500 color-purple margin-0-5 margin-bottom">
                        {t("profile.lifestyles")}
                      </h1>
                    )}
                    <div className="child-margins-y-1">
                      {this.renderQuestions(
                        isPuhti
                          ? QUESTIONS_FI
                          : lang == "en"
                          ? QUESTIONS_SE_EN
                          : QUESTIONS_SE,
                        this.state.currentUser,
                        t
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.renderSubmit()}
          </div>
        </ErrorBoundary>
      );
    } else if (this.state.loading) {
      return (
        <div className="padding-2 padding-y">
          <LoadingSpinner />
        </div>
      );
    } else if (this.state.error) {
      return (
        <div className="padding-2 padding-y">
          <ErrorMessage />
        </div>
      );
    } else {
      return null;
    }
  }
}
export default withTranslation()(Profile);
