import React, { useState, createContext } from "react";
import Modal from "../Modal";
import { useTranslation } from "react-i18next";
import axios from "axios";

export const ReviewContext = createContext(null);

const CategoryEditedModalContent = ({
  cancel,
  finalReportGenerated,
  category,
}) => {
  const { t } = useTranslation();
  return (
    <div className="padding-1" style={{ zIndex: 10 }}>
      <h1 className="weight-500 size-medium">
        {t("doctor_review.do_you_want_to_edit_answers")}?
      </h1>

      {!finalReportGenerated ? (
        <p className=" size-medium">{t("doctor_review.if_you_edit_answers")}</p>
      ) : (
        <p className=" size-medium">
          {t("doctor_review.if_you_edit_answers_redo_final_report", {
            category: category.name,
          })}
        </p>
      )}
    </div>
  );
};

const ReviewProvider = ({
  children,
  resultCategories,
  testGroups,
  orderId,
}) => {
  const [selectionsState, setSelectionsState] = useState([]);
  const [repliedSections, setRepliedSections] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [finalReportGenerated, setFinalReportGenerated] = useState(false);
  const [groups, setGroups] = useState(resultCategories);
  const { t } = useTranslation();
  /*
  const keepTrackOfRepliedSections = () => {
    const repliedSections = resultCategories.filter((resultCategory) =>
      resultCategory.results.every((result) =>
        result.options.some((option) =>
          selectionsState.some((s) => s.id == option.id)
        )
      )
    );

    setRepliedSections(repliedSections);
  };
  */

  const setCategorySummary = (value, category, key) => {
    const updatedCategoriesComments = groups.map((group) => {
      if (group.id === category.id) {
        group[key] = value;
      }
      return group;
    });
    setFinalReportGenerated(false);
    setGroups(updatedCategoriesComments);
  };

  const replaceFromSelectionsState = (oldSelections, options, option) => {
    const previousSelection = oldSelections.find((old) =>
      options.find((option) => option.id === old.id),
    );

    const newSelections = oldSelections.filter(
      (s) => s.id !== previousSelection.id,
    );

    return [...newSelections, option];
  };

  const updateSelectionsState = (option, options, category) => {
    let oldSelections = [...selectionsState];

    const isCategoryAnswer = category.answers.some((a) => a.id === option.id);

    if (isCategoryAnswer) {
      setCategorySummary(option, category, "answer");
      return;
    }

    const hasSelection = oldSelections.some((old) =>
      options.some((option) => option.id === old.id),
    );

    if (hasSelection) {
      const newSelections = replaceFromSelectionsState(
        oldSelections,
        options,
        option,
      );
      setSelectionsState(newSelections);
    } else {
      setSelectionsState([...oldSelections, option]);
    }
  };

  const updateCommentGeneratedSections = (category, action) => {
    if (!action) {
      console.error("provide an action to the function, either add or remove");
    }
    if (action === "add") {
      setRepliedSections([...repliedSections, category]);
    } else {
      const updatedRepliedSections = repliedSections.filter(
        (s) => s.id !== category.id,
      );
      setRepliedSections(updatedRepliedSections);
    }
  };

  const nukeCategoryWrittenAnswer = (category) => {
    const updatedGroups = groups.map((g) => {
      if (g.id === category.id) {
        g.writtenAnswer = null;
        return g;
      }
      return g;
    });
    setGroups(updatedGroups);
  };

  const handleSelectionsChange = (
    category,
    option,
    options,
    stateUpdateCallback,
  ) => {
    const repliedCategory = () => {
      return (
        repliedSections.length &&
        repliedSections.find((section) =>
          section.results.some((result) =>
            result.answers.some((o) => o.id === option.id),
          ),
        )
      );
    };

    const isCategoryOption = !category.results.some((r) =>
      r.answers.some((a) => a.id === option.id),
    );

    const categoryReplied = !!repliedCategory();

    if (categoryReplied || finalReportGenerated) {
      setModalContent([
        <CategoryEditedModalContent
          key={"1"}
          finalReportGenerated={finalReportGenerated}
          category={category}
        />,
        {
          cancel: () => {
            setModalContent(null);
          },
          accept: () => {
            setFinalReportGenerated(false);
            updateSelectionsState(option, options, category);
            updateCommentGeneratedSections(
              isCategoryOption ? category : repliedCategory(),
              "remove",
            );
            nukeCategoryWrittenAnswer(category);
            stateUpdateCallback();

            setModalContent(null);
          },
        },
      ]);
    } else {
      updateSelectionsState(option, options, category);
      stateUpdateCallback();
    }
  };

  const createFinalReportAnswerGroups = () => {
    const getAnswers = (category) => {
      const categoryAnswer = groups.find((g) => g.id === category.id)["answer"];

      const selections = doctorSelections.filter((selection) => {
        return category.results.some((result) =>
          result.answers.some((a) => a.id === selection.id),
        );
      });
      return categoryAnswer ? [...selections, categoryAnswer] : [...selections];
    };

    const getCategoryAnswer = (category) => {
      return groups.find((g) => g.id === category.id)["writtenAnswer"] || null;
    };

    const answerGroups = resultCategories
      .map((category) => {
        return {
          id: category.id,
          doctor_review_answers: getAnswers(category),
          comment: getCategoryAnswer(category),
        };
      })
      .filter((group) => group.doctor_review_answers[0] || group.comment);
    return answerGroups;
  };

  const createCategoryAnswerGroups = (answers, category) => {
    const categoryAnswer = groups.find((g) => g.id === category.id)["answer"];
    return [
      {
        id: category.id,
        doctor_review_answers:
          categoryAnswer && categoryAnswer
            ? [...answers, categoryAnswer]
            : [...answers],
        comment: null,
      },
    ];
  };

  const createStatement = (
    successCallback,
    answers,
    category,
    partial = true,
  ) => {
    const answerGroups = answers
      ? createCategoryAnswerGroups(answers, category)
      : createFinalReportAnswerGroups();

    axios
      .post(
        "/doctor/api/commerce_orders/" +
          orderId +
          "/doctor_review/generate_statement",
        { groups: answerGroups, partial: partial },
      )
      .then(({ data }) => {
        successCallback(data.statement);
      })
      .catch((error) => {});
  };

  const doctorSelections = selectionsState;
  return (
    <ReviewContext.Provider
      value={{
        doctorSelections,
        handleSelectionsChange: handleSelectionsChange,
        setModalContent,
        repliedSections,
        updateCommentGeneratedSections,
        finalReportGenerated,
        setFinalReportGenerated,
        setCategorySummary,
        groups,
        createStatement,
      }}
    >
      {children}
      <Modal
        open={modalContent ? modalContent : false}
        setOpen={() => {
          setModalContent(null);
          setModalOpen(false);
        }}
      >
        <div className="padding-1 padding-top">
          {modalContent && modalContent[0]}
          <div className="flex padding-1" style={{ justifyContent: "right" }}>
            <button
              className="button button--white margin-1 margin-right"
              onClick={() => {
                modalContent[1].cancel();
              }}
            >
              {t("doctor_review.cancel")}
            </button>
            <button
              className="button"
              onClick={() => {
                modalContent[1].accept();
              }}
            >
              {t("doctor_review.edit")}
            </button>
          </div>
        </div>
      </Modal>
    </ReviewContext.Provider>
  );
};
export default ReviewProvider;
