import Modal from "react-modal";
import React from "react";
import CloseIcon from "../assets/close-icon.svg";
import SVG from "react-inlinesvg";
import "../styles/components/_modal.scss";

const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    width: "80%",
    maxHeight: "90vh",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    borderRadius: 12,
    zIndex: 2,
  },
};
const ReactModal = ({ open = false, setOpen, children }) => {
  Modal.setAppElement("#root");
  return (
    <Modal
      isOpen={open}
      onRequestClose={() => {
        setOpen(false);
      }}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div>
        <div
          className="modal-close-icon"
          onClick={() => {
            setOpen(false);
          }}
        >
          <SVG
            src={CloseIcon}
            onClick={() => {
              setOpen(false);
            }}
          />
        </div>
        {children}
      </div>
    </Modal>
  );
};
export default ReactModal;
