import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../styles/components/_user-doctor-review.scss";
import externalLink from "../assets/external-link.svg";
import SVG from "react-inlinesvg";
import env from "../env";

const UserDoctorReviewAd = ({ orderNumber }) => {
  const { t } = useTranslation();

  const endpoint = t("main_navigation.store_short_link");
  const url = `${endpoint}/produkt/vital-lakarens-komment?order_number=${orderNumber}`;

  return (
    <>
      <div className={"card padding-1 margin-1 margin-bottom"}>
        <h1 className="size-medium weight-500 color-purple padding-1 padding-bottom">
          {t("doctor_review.doctor_review")}
        </h1>
        {
          <>
            <div className="margin-bottom margin-1 obfuscate">
              <p className="padding-1 padding-bottom">
                {t("doctor_review.order_doctor_review")}
              </p>
            </div>
          </>
        }
        <div>
          {<SVG src={externalLink} className={"labelIcon"} />}
          <a
            href={url}
            className="smallLabel"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("doctor_review.order_button_text")}
          </a>
        </div>
      </div>
    </>
  );
};

export default UserDoctorReviewAd;
