import React from "react";
import onClickOutside from "react-onclickoutside";
import AnimateHeight from "react-animate-height";
import classNames from "classnames";
import NavLink from "./NavLink";

import chevronUp from "../../assets/chevron-up.svg";
import SVG from "react-inlinesvg";

class NavDropdownLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submenuOpen: false,
      navHeight: "auto",
      handleOutsideClicks: false,
    };
  }

  componentDidMount() {
    const mql = window.matchMedia("(max-width: 979px)");
    mql.addListener(this.handleMediaChange.bind(this));
    this.handleMediaChange(mql);
  }

  renderIcon() {
    if (this.props.icon) {
      return <div className="nav-link__icon">{this.props.icon}</div>;
    }
  }

  handleMediaChange(mediaQueryList) {
    if (mediaQueryList.matches && this.props.stackOnMobile) {
      this.setState({
        navHeight: "auto",
        handleOutsideClicks: false,
      });
    } else {
      this.setState({
        navHeight: 0,
        handleOutsideClicks: true,
      });
    }
  }

  handleClickOutside(evt) {
    if (this.state.handleOutsideClicks) {
      this.setState({
        submenuOpen: false,
        navHeight: 0,
      });
    }
  }

  onClickParent(e) {
    this.setState(
      (state) => ({
        submenuOpen: !state.submenuOpen,
      }),
      () => {
        if (this.state.submenuOpen) {
          this.setState({
            navHeight: "auto",
          });
        } else {
          this.setState({
            navHeight: 0,
          });
        }
      },
    );
  }

  onClickSubmenu(e) {
    if (e.target === this.submenuWrapper) {
      e.stopPropagation();
    }
  }

  render() {
    const dropdownClassNames = classNames(
      "nav-link navigation-list__link navigation-list__link--dropdown",
      {
        "navigation-list__link--dropdown-stack": this.props.stackOnMobile,
      },
    );

    const submenuClassNames = classNames("navigation-list__submenu", {
      "navigation-list__submenu--active": this.state.submenuOpen,
    });

    return (
      <div
        className={dropdownClassNames}
        onClick={this.onClickParent.bind(this)}
      >
        {this.renderIcon()}
        <div className="nav-link__label">{this.props.label}</div>
        <SVG className="nav-link__arrow" src={chevronUp} />
        <AnimateHeight
          className={submenuClassNames}
          duration={200 / 1.618}
          height={this.state.navHeight}
          onAnimationEnd={() => this.setState({ heightAnimationEnded: true })}
          style={this.state.heightAnimationEnded ? { overflow: "visible" } : {}}
          applyInlineTransitions={false}
        >
          <div
            className="navigation-list__submenu-inner"
            onClick={this.onClickSubmenu.bind(this)}
            ref={(ref) => {
              this.submenuWrapper = ref;
            }}
          >
            {this.props.children}
          </div>
        </AnimateHeight>
      </div>
    );
  }
}

export default onClickOutside(NavDropdownLink);
