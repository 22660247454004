import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import env from "../env";

const app_name = env.APP_ID;
const defaultLanguage = "fi";
const selectedLanguage = localStorage.getItem("selectedLanguage");
const language = selectedLanguage || env.DEFAULT_LOCALE || defaultLanguage;

const resources = { fi: {}, sv: {}, en: {} };
Object.keys(resources).forEach(
  (locale) =>
    (resources[locale] = {
      translation: require(`./${app_name}/${locale}.json`),
    }),
);

i18n.use(initReactI18next).init({
  resources,
  lng: language,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
export default i18n;
