import React, { useState } from "react";
import ErrorBoundary from "./../components/ErrorBoundary";
import Modal from "./../components/Modal";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import CategorySummary from "../components/DoctorReview/CategorySummary";
import ReviewProvider from "../components/DoctorReview/ReviewProvider";
import { MarkdownRender } from "../components/Markdown/MarkdownRender";
import DoctorReviewDoctorAssigner from "../components/DoctorReview/ReviewAssigner";
import FinalReview from "../components/DoctorReview/FinalReview";
import DoctorResultCard from "../components/DoctorReview/DoctorResultCard";
import { ChevronsRight, ChevronsLeft } from "react-feather";
import styles from "../styles/components/_doctor-review.scss";
import { QUESTIONS_FI, QUESTIONS_SE, QUESTIONS_SE_EN } from "../data.js";
import { DEFAULT_LOCALE, APP_ID } from "../env";
import i18n from "../localization/i18n";

export const getDateString = (date) => {
  if (!date) return "";
  return new Date(date).toLocaleString(DEFAULT_LOCALE, {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
};

export const getProfileStringI18nKey = (key) =>
  ({
    identity_number: "doctor_review.identity_number",
    language: "doctor_review.language",
    age: "doctor_review.age",
    sex: "doctor_review.sex",
    height: "doctor_review.height",
    weight: "doctor_review.weight",
  })[key] || `doctor_review.${key}`;

export const translatedPackageName = (packageName, t) => {
  if (["custom", "unknown"].includes(packageName)) {
    return t(`doctor_review.package_${packageName}`);
  } else {
    return packageName;
  }
};

const DoctorReview = (props) => {
  const { profile, doctor_review, locale } = props;
  i18n.changeLanguage(locale);

  const { attributes } = doctor_review.data;
  const { doctor_profile, user_profile, order_locale } = attributes;

  const { t } = useTranslation();
  const orderId = props.order_id;
  const orderNumber = attributes.order.order_number;

  const [reviewer, setReviewer] = useState(null);
  const [sideBarToggled, setSideBarToggled] = useState(true);

  const resultCategoriesContainer = classNames("result-categories_container", {
    "disabled-opacity": false, //!reviewer,
  });

  const toggleSideBar = () => {
    setSideBarToggled(!sideBarToggled);
  };

  const renderPickReviewerDisclaimer = () => {
    return null;
  };

  const renderHeader = () => {
    return (
      <div className="flex">
        <div className="flex-1">
          <h1 className="weight-500 size-huge color-purple margin-1 margin-bottom">
            {t("doctor_review.order")} #{orderNumber}
          </h1>
          {APP_ID === "vital" && (
            <div class="margin-bottom margin-1">
              <h2 className="weight-500 size-medium color-purple margin-1 margin-bottom">
                {`${t("doctor_review.laboratory")}: ${
                  attributes.order.laboratory_name
                }`}
              </h2>
              <h2 className="weight-500 size-medium color-purple">
                {t("doctor_review.package_name")}:
              </h2>
              <p>{translatedPackageName(attributes.order.package_name, t)}</p>
            </div>
          )}
        </div>
        <div className="flex-1">
          <DoctorReviewDoctorAssigner
            setReviewer={setReviewer}
            orderId={orderId}
            selectedDoctor={attributes.doctor_profile}
          />
        </div>
      </div>
    );
  };

  const renderAdminComment = () => {
    if (!attributes.order.admin_comment) return null;

    return (
      <div className="card card--yellow padding-1 margin-1 margin-bottom">
        <h1 className="weight-500 size-medium color-purple margin-1 margin-bottom">
          {t("doctor_review.admin_comment")}
        </h1>

        <div className="card card--noshadow padding-1">
          <MarkdownRender
            markdown={attributes.order.admin_comment
              ?.split("\n")
              ?.join("  \n\r")}
          />
        </div>
      </div>
    );
  };

  const renderReopenComment = () => {
    if (!attributes.reopen_note) return null;

    const dateString = getDateString(attributes.reopened_at);

    return (
      <div className="card card--yellow padding-1 margin-1 margin-bottom">
        <h1 className="weight-500 size-medium color-purple margin-1 margin-bottom">
          {t("doctor_review.reopen_note_title") + " " + dateString}
        </h1>

        <div className="card card--noshadow padding-1">
          <MarkdownRender
            markdown={attributes.reopen_note.split("\n").join("  \n\r")}
          />
        </div>
      </div>
    );
  };

  const renderPreviousAnswers = () => {
    return (
      <div className="card padding-1 margin-1 margin-bottom">
        <h1 className="weight-500 size-medium color-purple margin-1 margin-bottom">
          {t("doctor_review.previous_statement")}
        </h1>

        <strong>{t("doctor_review.previous_statement_info")}</strong>
        <div className="card card--noshadow padding-1 background-gray">
          <MarkdownRender
            markdown={attributes.statement?.split("\n")?.join("  \n\r")}
          />
        </div>
      </div>
    );
  };

  const findMostRecentResult = (results) => {
    return results.find(
      (item) =>
        new Date(
          Math.max(...results.map((e) => new Date(e.last_changed))),
        ).toISOString() === item.last_changed,
    );
  };

  return (
    <ErrorBoundary>
      <ReviewProvider
        resultCategories={attributes.order.groups}
        orderId={orderId}
      >
        <div className="doctor-review-container">
          <div className="padding-1-5 flex-5" id="doctor-review-container">
            {renderHeader()}
            {renderAdminComment()}
            {attributes.status == "reopened" && renderReopenComment()}
            {attributes.order.groups.map((category, i) => {
              //const mostRecentResult = findMostRecentResult(category.results);

              return (
                <div className={resultCategoriesContainer} key={category.id}>
                  <div>
                    <h1
                      className="weight-500 size-large color-purple margin-1 margin-bottom"
                      style={{ display: "inline-block" }}
                    >
                      {category.name}
                    </h1>
                  </div>
                  {category.results.map((result) => {
                    return (
                      <DoctorResultCard
                        t={t}
                        {...result}
                        category={category}
                        key={result.id}
                      />
                    );
                  })}
                  <DoctorResultCard
                    t={t}
                    category={category}
                    answers={category.answers}
                  />
                  {/*mostRecentResult && (
                    <DoctorResultCard
                      t={t}
                      {...mostRecentResult}
                      category={category}
                    />
                  )*/}

                  {category.id !== 9999 && (
                    <CategorySummary
                      category={category}
                      resultCategories={attributes.order.groups}
                    />
                  )}
                </div>
              );
            })}
            {attributes.status == "reopened" && renderPreviousAnswers()}
            <FinalReview
              resultCategories={attributes.order.groups}
              orderId={orderId}
              doctorId={doctor_profile.id}
            />
          </div>
          <DoctorReviewCustomerInfoPanel
            userProfile={user_profile.attributes}
            orderLocale={order_locale}
            toggleSideBar={toggleSideBar}
            sideBarToggled={sideBarToggled}
          />
        </div>
      </ReviewProvider>
    </ErrorBoundary>
  );
};

const DoctorReviewCustomerInfoPanel = ({
  toggleSideBar,
  sideBarToggled,
  userProfile,
  orderLocale,
}) => {
  /*
  const testData = {
    user_id: 5,
    height: "188.0",
    weight: "56.0",
    weekly_exercise: null,
    sleep: null,
    pregnant: null,
    breastfeeding: null,
    diet_sweden: "vegetarian",
    medicated_illness: "yes",
    medicated_illness_text: "testasdasdasd medicated_illness_text\n",
    medicine_or_suppliments: "yes",
    medicine_or_suppliments_text: "text test!!!",
    reason_for_test: "disease_suspect",
    id: 5,
    date: "2022-03-16",
    alcohol_usage: null,
    area_type: null,
    diet: null,
    finland_region: null,
    healthy_diet: null,
    job_satisfaction: null,
    smoking: null,
    state_of_health: null,
    state_of_relationships: null,
    stress: null,
    exercise_habits: {
      outdoor: false,
      strength: false,
      endurance: false,
      no_exercise: false,
    },
    created_at: "2022-03-16T14:09:23.475Z",
    updated_at: "2022-03-16T15:04:22.736Z",
    home_animals: {
      cat: false,
      dog: false,
      other: false,
      no_animal: false,
      farm_animal: false,
      home_animal: false,
      small_animal: false,
    },
    disease_diagnoses: {
      no: false,
      other: false,
      asthma: false,
      cancer: false,
      diabetes: false,
      depression: false,
      overweight: false,
      food_allergy: false,
      osteoporosis: false,
      plant_allergy: false,
      animal_allergy: false,
      celiac_disease: false,
      hypothyroidism: false,
      hyperthyroidism: false,
      iron_deficiency: false,
      autoimmune_disease: false,
      lactose_intolerance: false,
      liver_kidney_disease: false,
      cardiovascular_disease: false,
    },
    bowel_disease_diagnoses: {
      no: false,
      ibs_c: false,
      ibs_d: false,
      ibs_m: false,
      ibd_cd: false,
      ibd_uc: false,
      intestinal_cancer: false,
    },
    bowel_disease_suspects: {
      no: false,
      ibs_c: false,
      ibs_d: false,
      ibs_m: false,
      something: false,
    },
    subsequent_surgeries: {
      caecum: false,
      gall_bladder: false,
      obesity_surgery: false,
      intestinal_surgery: false,
    },
    composition_of_stool: {
      type_1_hard: false,
      type_6_fluffy: false,
      type_7_liquid: false,
      type_2_solid_bumpy: false,
      type_4_solid_smooth: false,
      type_5_soft_discrete: false,
      type_3_solid_surface_cracks: false,
    },
    vegetables_usage_frequency: null,
    fruits_usage_frequency: null,
    cereals_usage_frequency: null,
    legumes_usage_frequency: null,
    nuts_and_seeds_usage_frequency: null,
    coffee_usage_frequency: null,
    sweets_usage_frequency: null,
    alcohol_usage_frequency: null,
    red_meat_usage_frequency: null,
    white_meat_usage_frequency: null,
    dairy_products_usage_frequency: null,
    fiber_usage_frequency: null,
    probiotics_usage_frequency: null,
    last_antibiotics_course_time: null,
    lifetime_antibiotics_course_estimate: null,
    childhood_multiple_antibiotics_courses: null,
    birth_position: null,
    breastfed_child: null,
    constipation_frequency: null,
    diarrhea_frequency: null,
    swelling_frequency: null,
    stomachache_frequency: null,
    abnormal_flatulence_frequency: null,
    stomach_cramps_frequency: null,
  };

  */

  const { t } = useTranslation();

  const { age, gender, identity_number, profile } = userProfile;

  const userInfo = {
    identity_number: identity_number ? identity_number : "",
    language: t(`languages.${orderLocale}`),
    age: age ? age : "",
    sex:
      gender === "female" ? t("doctor_review.woman") : t("doctor_review.man"),
    height: profile?.height
      ? profile.height + "cm"
      : t("doctor_review.no_answer"),
    weight: profile?.weight
      ? profile.weight + "kg"
      : t("doctor_review.no_answer"),
  };

  const lang = i18n.language;
  const isPuhti = APP_ID === "puhti";
  const questions = isPuhti
    ? QUESTIONS_FI
    : lang === "en"
      ? QUESTIONS_SE_EN
      : QUESTIONS_SE;

  const genderFilteredQuestions = questions.filter((q) => {
    if (q.sex && q.sex !== profile?.gender) {
      return false;
    }
    return true;
  });

  const SidePanelHeader = ({ toggleSideBar }) => {
    const [modalOpen, setModalOpen] = useState(false);

    return (
      <div className="client-info-sidepanel_header flex">
        <button
          className="toggle-button "
          onClick={() => {
            toggleSideBar();
          }}
        >
          {sideBarToggled ? (
            <ChevronsRight />
          ) : (
            <ChevronsLeft style={{ right: 8, position: "relative" }} />
          )}
        </button>
        {sideBarToggled && (
          <>
            <h3 className="color-purple size-medium weight-600 side-panel-header">
              {t("doctor_review.customer_info")}{" "}
            </h3>
            <button
              className="show-more-client-info-button size-medium color-purple"
              onClick={() => {
                setModalOpen(true);
              }}
            >
              {t("doctor_review.show_client_info")}
            </button>
          </>
        )}
        <Modal
          open={modalOpen}
          setOpen={() => {
            setModalOpen(false);
          }}
        >
          <div className="margin-1">
            {profile && renderProfileAnswersAndInfo()}
          </div>
        </Modal>
      </div>
    );
  };

  const renderReply = (question) => {
    if (!profile) return;

    let clientAnswer = profile[question.name];

    if (question.name === "medicine_or_suppliments" && clientAnswer === "yes") {
      clientAnswer = profile["medicine_or_suppliments_text"];
      return clientAnswer || t("doctor_review.no_answer");
    }
    if (question.name === "medicated_illness" && clientAnswer === "yes") {
      clientAnswer = profile["medicated_illness_text"];
      return clientAnswer || t("doctor_review.no_answer");
    }

    if (clientAnswer === null) {
      return t("doctor_review.no_answer");
    } else if (question.type === "radio" && clientAnswer) {
      return question.options.find((o) => o.value === clientAnswer).label;
    } else if (question.type === "checkbox" && clientAnswer) {
      const trueKeys = Object.keys(clientAnswer).filter(
        (key) => clientAnswer[key] === true,
      );

      const answers = question.options.filter((o) =>
        trueKeys.includes(o.value),
      );

      if (answers.length === 0) return t("doctor_review.no_answer");
      return answers.map((o, index) => `${index + 1}) ${o.label}`).join(", ");
    }

    return clientAnswer;
  };

  const renderUpdatedAtString = () => {
    if (profile && profile.updated_at) {
      const dateString = getDateString(profile.updated_at);
      return (
        <>
          <h1 className="weight-500 size-small color-purple margin-0-75 margin-top">
            {t("doctor_review.last_customer_update_at")}
          </h1>
          <h2 className="weight-500 size-medium color-purple margin-0-75 margin-bottom">
            {dateString}
          </h2>
          <div className="line-divider" />
        </>
      );
    } else return;
  };

  const renderProfileAnswersAndInfo = () => {
    return (
      <>
        {Object.keys(userInfo).map((keyName, i) => (
          <div key={keyName}>
            <h1 className="weight-500 size-small color-purple margin-0-75 margin-top">
              {t(getProfileStringI18nKey(keyName))}
            </h1>
            <h2 className="weight-500 size-medium color-purple margin-0-75 margin-bottom">
              {userInfo[keyName]}
            </h2>
            <div className="line-divider" />
          </div>
        ))}
        {profile &&
          genderFilteredQuestions.map((q, i) => {
            return (
              <div key={q.title}>
                <h1 className="weight-500 size-small color-purple margin-0-75 margin-top">
                  {q.title}
                </h1>
                <h2 className="weight-500 size-medium color-purple margin-0-75 margin-bottom">
                  {renderReply(q)}
                </h2>

                {i + 1 !== questions.length && <div className="line-divider" />}
              </div>
            );
          })}
      </>
    );
  };

  return (
    <div className="side-bar">
      <div className="sticky">
        <SidePanelHeader toggleSideBar={toggleSideBar} />

        {sideBarToggled && (
          <div className="client-info-sidepanel_content">
            {renderUpdatedAtString()}
            {renderProfileAnswersAndInfo()}
          </div>
        )}
      </div>
    </div>
  );
};
export default DoctorReview;
