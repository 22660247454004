import React, { Component } from "react";
import { Redirect } from "react-router";
import SVG from "react-inlinesvg";
import classNames from "classnames";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import axios from "axios";
import NumberInput from "../components/NumberInput";
import Question from "../components/Question";
import update from "immutability-helper";
import { withTranslation } from "react-i18next";
import logoSymbol from "../assets/logo-symbol.svg";

import { QUESTIONS_FI, QUESTIONS_SE, QUESTIONS_SE_EN } from "../data.js";
import { APP_ID } from "../env";
import i18n from "../localization/i18n.js";

class Onboarding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      loading: false,
      profileData: {},
      onboardingComplete: false,
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleStepComplete = this.handleStepComplete.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    axios
      .get("/api/profile")
      .then((response) => {
        this.setState({
          loading: false,
          profileData: { ...response.data },
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: error,
        });
        console.error(error);
      });
  }

  handleOnChange(value, name) {
    const oldState = this.state.profileData;

    const newState = update(oldState, { [name]: { $set: value } });

    this.setState({ profileData: newState });
  }

  handleStepComplete(event, saveProfile) {
    this.setState({ step: this.state.step + 1 });

    if (saveProfile) {
      this.handleSubmit();
    }
  }

  handleSubmit(onboardingComplete) {
    this.setState({ loading: true }, () => {
      axios
        .put("/api/profile", this.state.profileData)
        .then((response) => {
          this.setState({ loading: false, onboardingComplete });
        })
        .catch((error) => {
          this.setState({
            loading: false,
            error: error,
          });
          console.error(error);
        });
    });
  }

  handleComplete() {
    this.setState({ onboardingComplete: true });
  }

  renderNumberInputs(t) {
    return (
      <div className="flex justify-center text-align-left">
        <div className="card flex child-margins-x-1 justify-center padding-1">
          <NumberInput
            label={t("onboarding.weight")}
            suffix="kg"
            name="weight"
            min={20}
            max={500}
            value={this.state.profileData.weight || ""}
            onChange={this.handleOnChange}
            placeholder={`${t("onboarding.weight")} kg`}
          />
          <NumberInput
            label={t("onboarding.height")}
            suffix="cm"
            name="height"
            min={60}
            max={300}
            value={this.state.profileData.height || ""}
            onChange={this.handleOnChange}
            placeholder={`${t("onboarding.height")} cm`}
          />
        </div>
      </div>
    );
  }

  renderQuestions() {
    const lang = i18n.language;
    const isPuhti = APP_ID === "puhti";
    const questions = isPuhti ? QUESTIONS_FI : lang === "en" ? QUESTIONS_SE_EN : QUESTIONS_SE;

    return questions.map((question) => {
      return (
        <Question
          key={question.id}
          id={question.id}
          title={question.title}
          type={question.type}
          name={question.name}
          options={question.options}
          value={this.state.profileData[question.name] || ""}
          onChange={this.handleOnChange}
        />
      );
    });
  }

  renderStepOne(t) {
    return (
      <div className="text-align-center row center-m">
        <div className="col-xs-12 col-m-10 col-l-8">
          <div className="onboarding-animate__left">
            <SVG
              src={logoSymbol}
              className={`width-5 height-5 margin-auto-x block`}
            />
            <div className="margin-1 margin-y color-white">
              <h1 className="size-huge weight-500 margin-0-5 margin-bottom">
                {t("onboarding.welcome_to_puhti")}
              </h1>
              <p className="size-medium">
                {t("onboarding.before_results_questions_disclaimer")}
              </p>
            </div>
          </div>

          <div className="onboarding-animate__appear">
            <div className="margin-1-5 margin-top">
              <button
                onClick={this.handleStepComplete}
                className="button button--large"
              >
                {t("onboarding.start")}
              </button>
            </div>
            <div className="margin-1-5 margin-top">
              <button
                onClick={() => this.handleSubmit(true)}
                className="link link--blue-light"
              >
                {t("onboarding.skip_questionare")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderStepTwo(t) {
    return (
      <div className="text-align-center">
        <div className="margin-1-5 margin-bottom onboarding-animate__left">
          <h1 className="size-huge weight-500 color-purple margin-0-5 margin-bottom">
            {t("onboarding.input_weight_and_height")}
          </h1>
          <p className="size-medium">{t("onboarding.for_bmi")}</p>
        </div>
        <div className="onboarding-animate__appear">
          {this.renderNumberInputs(t)}
          <div className="margin-2 margin-top">
            <button
              onClick={this.handleStepComplete}
              className="button button--large"
            >
              {t("onboarding.continue")}
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderStepThree(t) {
    return (
      <div className="padding-2 padding-y">
        <div className="onboarding-animate__left">
          <div className="margin-2 margin-y text-align-center">
            <div className="row center-m">
              <div className="col-xs-12 col-m-10 col-l-8">
                <h1 className="size-huge weight-500 color-purple margin-0-5 margin-bottom">
                  {t("onboarding.lifestyle_related_questions")}
                </h1>
                <p className="size-medium">
                  {t("onboarding.13_questions_related_to_your_health")}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="onboarding-animate__appear">
          <div className="child-margins-y-1">{this.renderQuestions()}</div>
          <div className="margin-1-5 margin-top text-align-center">
            <button
              onClick={(event) => this.handleStepComplete(event, true)}
              className="button button--large"
            >
              {t("onboarding.continue")}
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderStepFour(t) {
    return (
      <div className="text-align-center row center-m">
        <div className="col-xs-12 col-m-10 col-l-8">
          <div className="onboarding-animate__left">
            <div className="margin-1 margin-bottom color-white">
              <h1 className="size-huge weight-500 margin-0-25 margin-bottom">
                {t("onboarding.thanks_for_replies")}
              </h1>
              <p className="size-medium">
                {t("onboarding.you_can_go_to_results")}.
              </p>
            </div>
          </div>
          <div className="onboarding-animate__appear">
            <div className="margin-1-5 margin-top">
              <button
                onClick={this.handleComplete}
                className="button button--large"
              >
                {t("onboarding.show_results")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { t } = this.props;
    const bgClasses = classNames("", {
      "bg-purple": this.state.step === 1 || this.state.step === 4,
      "bg-blue-lightest": this.state.step === 2 || this.state.step === 3,
    });

    if (this.state.onboardingComplete) {
      return <Redirect to="/" />;
    }

    return (
      <div className={`onboarding-wrapper ${bgClasses}`}>
        <TransitionGroup component={null}>
          <CSSTransition
            key={this.state.step}
            timeout={{ enter: 2000, exit: 1000 }}
            classNames="onboarding"
            unmountOnExit
          >
            <div className={`onboarding-wrapper__inner ${bgClasses}`}>
              <div className="container">
                <div className="row center-m text-align-left">
                  <div className="col-xs-12 col-m-10">
                    {this.state.step === 1 && this.renderStepOne(t)}
                    {this.state.step === 2 && this.renderStepTwo(t)}
                    {this.state.step === 3 && this.renderStepThree(t)}
                    {this.state.step === 4 && this.renderStepFour(t)}
                  </div>
                </div>
              </div>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>
    );
  }
}
export default withTranslation()(Onboarding);
