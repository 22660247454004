import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

class NavLink extends React.Component {
  renderIcon() {
    if (this.props.icon) {
      return <div className="nav-link__icon">{this.props.icon}</div>;
    }
  }

  handleOnClickExternal(e) {
    e.preventDefault();
    window.location = this.props.href;
  }

  render() {
    return (
      <Link
        className={`nav-link ${this.props.href ? "nav-link--external" : ""} ${
          this.props.menuitem ? "nav-link--menuitem" : ""
        }`}
        external={this.props.href}
        to={this.props.href ? "" : this.props.to}
        onClick={
          this.props.href
            ? this.handleOnClickExternal.bind(this)
            : this.props.onClick
        }
      >
        {this.renderIcon()}
        <div className="nav-link__label">{this.props.label}</div>
      </Link>
    );
  }
}
export default withTranslation()(NavLink);
