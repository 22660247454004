import React, { useState } from "react";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18n from "../../localization/i18n";
import RichTextEditor from "react-rte";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { MarkdownRender } from "./MarkdownRender";
import { markdownToDraft } from "markdown-draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import styles from "./Markdown.module.scss";

const defaultConfig = {
  extraProps: { tabIndex: "-1" },
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD" },
    { label: "Italic", style: "ITALIC" },
    { label: "Code", style: "CODE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

const draftJsToolbar = {
  options: ["inline"],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["bold", "italic"],
  },
};

export const MarkdownEditor = ({
  inputId,
  textInputRef,
  toolbarConfig = defaultConfig,
  propsDefaultValue,
  valueFrom = "markdown",
  hidePreviewButton = false,
  editorLabel,
  children,
  draftJsMarkdown,
  onBlur,
  onChange,
}) => {
  const { t } = useTranslation();

  const [preview, setPreview] = useState(false);
  const input = document.getElementById(inputId) || textInputRef;

  let defaultValue;
  if (!draftJsMarkdown) {
    if (propsDefaultValue) {
      defaultValue = RichTextEditor.createValueFromString(
        propsDefaultValue,
        valueFrom,
      );
    } else {
      defaultValue =
        (input?.value && input?.value.length > 0) ||
        (input?.current?.defaultValue &&
          input?.current?.defaultValue.length > 0)
          ? RichTextEditor.createValueFromString(
              input?.value || input.current?.defaultValue,
              valueFrom,
            )
          : RichTextEditor.createEmptyValue();
    }
  } else {
    const markdownString = draftJsMarkdown;
    const rawData = markdownToDraft(markdownString);
    const contentState = convertFromRaw(rawData);
    defaultValue = EditorState.createWithContent(contentState);
  }

  const [editorValue, setEditorValue] = useState(defaultValue);

  const onDraftJsEditorStateChange = (editorState) => {
    setEditorValue(editorState);
  };

  const getParsedValue = () => {
    const blocks = convertToRaw(editorValue.getCurrentContent()).blocks;
    const value = blocks
      .map((block) => (!block.text.trim() && "\n") || block.text)
      .join("\n");
    return value;
  };

  if (input || draftJsMarkdown) {
    return (
      <I18nextProvider i18n={i18n}>
        {!preview ? (
          <div className={styles.markdown}>
            <div className={styles.editorWrapper}>
              {editorLabel && <label>{editorLabel}</label>}
              {!draftJsMarkdown && (
                <RichTextEditor
                  value={editorValue}
                  onBlur={() => {
                    if (onBlur) onBlur(editorValue.toString("markdown"));
                  }}
                  onChange={(value) => {
                    if (input.current) {
                      input.current.value = editorValue.toString("markdown");
                    } else {
                      input.value = editorValue.toString("markdown");
                    }

                    setEditorValue(value);
                    if (onChange) {
                      onChange(editorValue.toString("markdown"));
                    }
                  }}
                  toolbarConfig={toolbarConfig}
                />
              )}
              {draftJsMarkdown && (
                <Editor
                  onBlur={() => {
                    const parseValue = getParsedValue();
                    if (onBlur) {
                      onBlur(parseValue);
                    }
                  }}
                  onChange={() => {
                    const parseValue = getParsedValue();
                    if (onChange) {
                      onChange(parseValue);
                    }
                  }}
                  toolbar={draftJsToolbar}
                  editorState={editorValue}
                  onEditorStateChange={onDraftJsEditorStateChange}
                />
              )}
            </div>
          </div>
        ) : (
          <div className={styles.renderedMarkdown}>
            <MarkdownRender
              markdown={
                draftJsMarkdown
                  ? convertToRaw(editorValue.getCurrentContent())
                      .blocks.map((b) => b.text.replace("\n", "  \n"))
                      .join("  \n\r")
                  : editorValue.toString("markdown")
              }
            />
          </div>
        )}

        <div className="margin-1 margin-top flex justify-end">
          {children && children}
          {!hidePreviewButton && (
            <button
              onClick={(e) => {
                e.preventDefault();
                setPreview(!preview);
              }}
              className="button"
            >
              {preview ? t("markdown.edit") : t("markdown.preview")}
            </button>
          )}
        </div>
      </I18nextProvider>
    );
  } else {
    return null;
  }
};
