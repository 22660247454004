import { Container } from "unstated";
import axios from "axios";
export default class OrdersContainer extends Container {
  state = {
    list: [],
    active: null,
    loading: false,
    error: null,
  };

  loadOrder = ({ id, lang }) => {
    let path = `/api/orders/${id}`;
    if (lang) {
      path = `${path}?locale=${lang}`;
    }
    this.setState({
      error: null,
      loading: true,
    });
    axios
      .get(path)
      .then((response) => {
        const order = response.data;
        this.setState({
          active: order,
          loading: false,
          error: null,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: error,
        });
      });
  };

  loadList = (lang) => {
    let path = "/api/orders";
    if (lang) {
      path = `${path}?locale=${lang}`;
    }
    this.setState({
      error: null,
      loading: true,
    });
    axios
      .get(path)
      .then((response) => {
        const orders = response.data.orders;
        this.setState({ list: orders });
        if (orders.length === 0) {
          this.setState({ loading: false });
        }
        return orders;
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: error
        });
      });
  };
}
