export const QUESTIONS_FI = [
  {
    id: 1,
    title: "Mikä seuraavista kuvaa ruokavaliotasi?",
    type: "radio",
    name: "diet",
    options: [
      {
        label: "Sekaruokavalio (syön sekä lihaa että kasviksia)",
        value: "mixed",
      },
      {
        label: "Kasvisruokavalio (syön pääosin kasvisruokaa)",
        value: "vegetarian",
      },
      { label: "Erityisruokavalio", value: "other" },
    ],
  },
  {
    id: 2,
    title: "Syötkö mielestäsi terveellisesti?",
    type: "radio",
    name: "healthy_diet",
    options: [
      { label: "Kyllä", value: "yes" },
      { label: "En", value: "no" },
      { label: "En osaa sanoa", value: "unknown" },
    ],
  },
  {
    id: 3,
    title:
      "Mikä seuraavista kuvaa liikuntatottumuksiasi tavallisena viikkona? Voit valita useamman vaihtoehdon.",
    type: "checkbox",
    name: "exercise_habits",
    options: [
      { label: "En harrasta liikuntaa juuri lainkaan", value: "no_exercise" },
      {
        label:
          "Harrastan reipasta ulkoilua  (esim. pyöräily, reipas kävely, työmatkaliikunta jne.)",
        value: "outdoor",
      },
      {
        label:
          "Harrastan rasittavampaa kestävyysliikuntaa (esim. juoksu, pyöräily, hiihto, uinti, porraskävely, mailapelit)",
        value: "endurance",
      },
      {
        label:
          "Harrastan lihaskuntoa ja liikkuvuutta ylläpitävää ja kehittävää liikuntaa (esim. kuntosali, kuntopiiri, jumpat, tanssi)",
        value: "strength",
      },
    ],
  },
  {
    id: 4,
    title: "Montako tuntia tavallisesti harrastat liikuntaa viikossa yhteensä?",
    type: "select",
    name: "weekly_exercise",
    options: [
      { label: "En lainkaan", value: 0 },
      { label: "1 tunnin", value: 1 },
      { label: "2 tuntia", value: 2 },
      { label: "3 tuntia", value: 3 },
      { label: "4 tuntia", value: 4 },
      { label: "5 tuntia", value: 5 },
      { label: "6 tuntia", value: 6 },
      { label: "7 tuntia", value: 7 },
      { label: "8 tuntia", value: 8 },
      { label: "9 tuntia", value: 9 },
      { label: "10 tuntia", value: 10 },
      { label: "11 tuntia", value: 11 },
      { label: "12 tuntia", value: 12 },
      { label: "13 tuntia", value: 13 },
      { label: "14 tuntia", value: 14 },
      { label: "15 tuntia", value: 15 },
      { label: "16 tuntia", value: 16 },
      { label: "17 tuntia", value: 17 },
      { label: "18 tuntia", value: 18 },
      { label: "19 tuntia", value: 19 },
      { label: "20 tuntia", value: 20 },
      { label: "21 tuntia", value: 21 },
      { label: "22 tuntia", value: 22 },
      { label: "23 tuntia", value: 23 },
      { label: "24 tuntia", value: 24 },
      { label: "25 tuntia", value: 25 },
      { label: "26 tuntia", value: 26 },
      { label: "27 tuntia", value: 27 },
      { label: "28 tuntia", value: 28 },
      { label: "29 tuntia", value: 29 },
      { label: "30 tuntia", value: 30 },
    ],
  },
  {
    id: 5,
    title: "Montako tuntia nukut tavallisesti vuorokaudessa?",
    type: "select",
    name: "sleep",
    options: [
      { label: "1 tunnin", value: 1 },
      { label: "2 tuntia", value: 2 },
      { label: "3 tuntia", value: 3 },
      { label: "4 tuntia", value: 4 },
      { label: "5 tuntia", value: 5 },
      { label: "6 tuntia", value: 6 },
      { label: "7 tuntia", value: 7 },
      { label: "8 tuntia", value: 8 },
      { label: "9 tuntia", value: 9 },
      { label: "10 tuntia", value: 10 },
      { label: "11 tuntia", value: 11 },
      { label: "12 tuntia", value: 12 },
      { label: "13 tuntia", value: 13 },
      { label: "14 tuntia", value: 14 },
    ],
  },
  {
    id: 6,
    title: "Millainen on terveydentilasi omasta mielestäsi?",
    type: "radio",
    name: "state_of_health",
    options: [
      { label: "Hyvä", value: "good" },
      { label: "Melko hyvä", value: "above_average" },
      { label: "Keskitasoinen", value: "average" },
      { label: "Melko huono", value: "below_average" },
      { label: "Huono", value: "bad" },
    ],
  },
  {
    id: 7,
    title: "Koetko olevasi stressaantunut tai uupunut?",
    type: "radio",
    name: "stress",
    options: [
      { label: "Kyllä", value: "yes" },
      { label: "En", value: "no" },
      { label: "Välillä", value: "occasionally" },
    ],
  },
  {
    id: 8,
    title: "Oletko tyytyväinen ihmissuhteisiisi? (perhe, ystävät yms.)",
    type: "radio",
    name: "state_of_relationships",
    options: [
      { label: "Kyllä", value: "yes" },
      { label: "En", value: "no" },
      { label: "En osaa sanoa", value: "unknown" },
    ],
  },
  {
    id: 9,
    title: "Oletko tyytyväinen työhösi?",
    type: "radio",
    name: "job_satisfaction",
    options: [
      { label: "Kyllä", value: "yes" },
      { label: "En", value: "no" },
      { label: "En osaa sanoa", value: "unknown" },
    ],
  },
  {
    id: 10,
    title: "Tupakoitko?",
    type: "radio",
    name: "smoking",
    options: [
      { label: "Kyllä", value: "yes" },
      { label: "En", value: "no" },
      { label: "Satunnaisesti", value: "occasionally" },
    ],
  },
  {
    id: 11,
    title:
      "Montako annosta alkoholia käytät keskimäärin viikossa (yksi annos on 33cl III-olutta tai 12cl viiniä)?",
    type: "radio",
    name: "alcohol_usage",
    options: [
      { label: "0", value: "units_0" },
      { label: "1-7", value: "units_1" },
      { label: "8-14", value: "units_8" },
      { label: "15-24", value: "units_15" },
      { label: "yli 24", value: "units_24" },
    ],
  },
  {
    id: 12,
    title: "Missä päin Suomea asut?",
    type: "radio",
    name: "finland_region",
    options: [
      { label: "Etelä-Suomen lääni", value: "southern" },
      { label: "Länsi-Suomen lääni", value: "western" },
      { label: "Itä-Suomen lääni", value: "eastern" },
      { label: "Oulun lääni", value: "oulu" },
      { label: "Lapin lääni", value: "lapland" },
    ],
  },
  {
    id: 13,
    title: "Millaisella alueella asut?",
    type: "radio",
    name: "area_type",
    options: [
      { label: "Pääkaupunkiseudulla", value: "capital_region" },
      {
        label: "Muussa suuressa kaupungissa, yli 100 000 asukasta",
        value: "other_city",
      },
      { label: "Kaupungissa jossa 20 000 - 100 000 asukasta", value: "town" },
      { label: "Pienempi taajama tai kunta", value: "village" },
      { label: "Maaseutu, haja-asutusalue", value: "rural" },
    ],
  },
];

export const MICROBIOME_QUESTIONS = [
  {
    id: "17",
    title: "Onko sinulla eläimiä kotona?",
    type: "checkbox",
    name: "home_animals",
    options: [
      { label: "Ei", value: "no_animal" },
      { label: "Koira", value: "dog" },
      { label: "Kissa", value: "cat" },
      { label: "Hamsteri, marsu tai muu pieni eläin", value: "small_animal" },
      {
        label: "Kotieläimiä (hevonen, lammas, lehmä...)",
        value: "farm_animal",
      },
      { label: "Kyllä, jokin muu", value: "other" },
    ],
  },
  {
    id: "18",
    title: "Kuinka usein käytät seuraavia?",
    type: "matrix",
    questions: [
      {
        id: "18_1",
        title: "Kasvikset",
        type: "radio",
        name: "vegetables_usage_frequency",
        options: [
          { label: "Useasti päivässä", value: "multiple_times_a_day" },
          { label: "Päivittäin", value: "every_day" },
          { label: "Kerran päivässä", value: "once_a_day" },
          { label: "Muutamalla aterialla viikossa", value: "few_a_week" },
          { label: "Kerran viikossa", value: "once_a_week" },
          { label: "Harvemmin", value: "not_often" },
          { label: "En koskaan", value: "never" },
        ],
      },
      {
        id: "18_2",
        title: "Hedelmät",
        type: "radio",
        name: "fruits_usage_frequency",
        options: [
          { label: "Useasti päivässä", value: "multiple_times_a_day" },
          { label: "Päivittäin", value: "every_day" },
          { label: "Kerran päivässä", value: "once_a_day" },
          { label: "Muutamalla aterialla viikossa", value: "few_a_week" },
          { label: "Kerran viikossa", value: "once_a_week" },
          { label: "Harvemmin", value: "not_often" },
          { label: "En koskaan", value: "never" },
        ],
      },
      {
        id: "18_3",
        title: "Viljat",
        type: "radio",
        name: "cereals_usage_frequency",
        options: [
          { label: "Useasti päivässä", value: "multiple_times_a_day" },
          { label: "Päivittäin", value: "every_day" },
          { label: "Kerran päivässä", value: "once_a_day" },
          { label: "Muutamalla aterialla viikossa", value: "few_a_week" },
          { label: "Kerran viikossa", value: "once_a_week" },
          { label: "Harvemmin", value: "not_often" },
          { label: "En koskaan", value: "never" },
        ],
      },
      {
        id: "18_4",
        title: "Palkokasvit (esim. herneet, pavut, linssit...)",
        type: "radio",
        name: "legumes_usage_frequency",
        options: [
          { label: "Useasti päivässä", value: "multiple_times_a_day" },
          { label: "Päivittäin", value: "every_day" },
          { label: "Kerran päivässä", value: "once_a_day" },
          { label: "Muutamalla aterialla viikossa", value: "few_a_week" },
          { label: "Kerran viikossa", value: "once_a_week" },
          { label: "Harvemmin", value: "not_often" },
          { label: "En koskaan", value: "never" },
        ],
      },
      {
        id: "18_5",
        title: "Pähkinät ja siemenet",
        type: "radio",
        name: "nuts_and_seeds_usage_frequency",
        options: [
          { label: "Useasti päivässä", value: "multiple_times_a_day" },
          { label: "Päivittäin", value: "every_day" },
          { label: "Kerran päivässä", value: "once_a_day" },
          { label: "Muutamalla aterialla viikossa", value: "few_a_week" },
          { label: "Kerran viikossa", value: "once_a_week" },
          { label: "Harvemmin", value: "not_often" },
          { label: "En koskaan", value: "never" },
        ],
      },
      {
        id: "18_6",
        title: "Kahvi",
        type: "radio",
        name: "coffee_usage_frequency",
        options: [
          { label: "Useasti päivässä", value: "multiple_times_a_day" },
          { label: "Päivittäin", value: "every_day" },
          { label: "Kerran päivässä", value: "once_a_day" },
          { label: "Muutamalla aterialla viikossa", value: "few_a_week" },
          { label: "Kerran viikossa", value: "once_a_week" },
          { label: "Harvemmin", value: "not_often" },
          { label: "En koskaan", value: "never" },
        ],
      },
      {
        id: "18_7",
        title: "Karkit ja makeat herkut",
        type: "radio",
        name: "sweets_usage_frequency",
        options: [
          { label: "Useasti päivässä", value: "multiple_times_a_day" },
          { label: "Päivittäin", value: "every_day" },
          { label: "Kerran päivässä", value: "once_a_day" },
          { label: "Muutamalla aterialla viikossa", value: "few_a_week" },
          { label: "Kerran viikossa", value: "once_a_week" },
          { label: "Harvemmin", value: "not_often" },
          { label: "En koskaan", value: "never" },
        ],
      },
      {
        id: "18_8",
        title: "Alkoholi",
        type: "radio",
        name: "alcohol_usage_frequency",
        options: [
          { label: "Useasti päivässä", value: "multiple_times_a_day" },
          { label: "Päivittäin", value: "every_day" },
          { label: "Kerran päivässä", value: "once_a_day" },
          { label: "Muutamalla aterialla viikossa", value: "few_a_week" },
          { label: "Kerran viikossa", value: "once_a_week" },
          { label: "Harvemmin", value: "not_often" },
          { label: "En koskaan", value: "never" },
        ],
      },
      {
        id: "18_9",
        title: "Punainen liha",
        type: "radio",
        name: "red_meat_usage_frequency",
        options: [
          { label: "Useasti päivässä", value: "multiple_times_a_day" },
          { label: "Päivittäin", value: "every_day" },
          { label: "Kerran päivässä", value: "once_a_day" },
          { label: "Muutamalla aterialla viikossa", value: "few_a_week" },
          { label: "Kerran viikossa", value: "once_a_week" },
          { label: "Harvemmin", value: "not_often" },
          { label: "En koskaan", value: "never" },
        ],
      },
      {
        id: "18_10",
        title: "Vaalea liha",
        type: "radio",
        name: "white_meat_usage_frequency",
        options: [
          { label: "Useasti päivässä", value: "multiple_times_a_day" },
          { label: "Päivittäin", value: "every_day" },
          { label: "Kerran päivässä", value: "once_a_day" },
          { label: "Muutamalla aterialla viikossa", value: "few_a_week" },
          { label: "Kerran viikossa", value: "once_a_week" },
          { label: "Harvemmin", value: "not_often" },
          { label: "En koskaan", value: "never" },
        ],
      },
      {
        id: "18_11",
        title: "Maitotuotteet",
        type: "radio",
        name: "dairy_products_usage_frequency",
        options: [
          { label: "Useasti päivässä", value: "multiple_times_a_day" },
          { label: "Päivittäin", value: "every_day" },
          { label: "Kerran päivässä", value: "once_a_day" },
          { label: "Muutamalla aterialla viikossa", value: "few_a_week" },
          { label: "Kerran viikossa", value: "once_a_week" },
          { label: "Harvemmin", value: "not_often" },
          { label: "En koskaan", value: "never" },
        ],
      },
    ],
  },
  {
    id: "19",
    title: "Kuinka usein käytät seuraavia?",
    type: "matrix",
    questions: [
      {
        id: "19_1",
        title: "Erilliset kuituvalmisteet",
        type: "radio",
        name: "fiber_usage_frequency",
        options: [
          { label: "Kerran päivässä", value: "once_a_day" },
          { label: "Muutamalla aterialla viikossa", value: "few_a_week" },
          { label: "Kerran viikossa", value: "once_a_week" },
          { label: "Harvemmin", value: "not_often" },
          { label: "En koskaan", value: "never" },
        ],
      },
      {
        id: "19_2",
        title: "Maitohappobakteerit/probiootit",
        type: "radio",
        name: "probiotics_usage_frequency",
        options: [
          { label: "Kerran päivässä", value: "once_a_day" },
          { label: "Muutamalla aterialla viikossa", value: "few_a_week" },
          { label: "Kerran viikossa", value: "once_a_week" },
          { label: "Harvemmin", value: "not_often" },
          { label: "En koskaan", value: "never" },
        ],
      },
    ],
  },
  {
    id: "20",
    title: "Onko sinulla todettu jokin seuraavista sairauksista?",
    type: "checkbox",
    name: "disease_diagnoses",
    options: [
      {
        label: "Ei, minulla ei ole todettu mitään näistä sairauksista",
        value: "no",
      },
      { label: "Ylipaino", value: "overweight" },
      { label: "Astma", value: "asthma" },
      { label: "Diabetes", value: "diabetes" },
      { label: "Raudanpuutos", value: "iron_deficiency" },
      { label: "Allergia eläimelle", value: "animal_allergy" },
      { label: "Allergia kasville", value: "plant_allergy" },
      { label: "Allergia ruoalle", value: "food_allergy" },
      { label: "Osteoporoosi", value: "osteoporosis" },
      { label: "Masennus", value: "depression" },
      { label: "Kilpirauhasen vajaatoiminta", value: "hypothyroidism" },
      { label: "Kilpirauhasen liikatoiminta", value: "hyperthyroidism" },
      { label: "Sydän- tai verisuonitauti", value: "cardiovascular_disease" },
      { label: "Maksa- tai munuaissairaus", value: "liver_kidney_disease" },
      { label: "Syöpä", value: "cancer" },
      {
        label: "Keliakia tai yliherkkyys gluteenille",
        value: "celiac_disease",
      },
      {
        label: "Laktoosi-intoleranssi tai maito-allergia",
        value: "lactose_intolerance",
      },
      { label: "Autoimmuunisairaus", value: "autoimmune_disease" },
      { label: "Kyllä, jokin muu", value: "other" },
    ],
  },
  {
    id: "21",
    title: "Onko sinulla todettu jotain seuraavista suolistosairauksista?",
    type: "checkbox",
    name: "bowel_disease_diagnoses",
    options: [
      { label: "Ei diagnosoituja suolistosairauksia", value: "no" },
      { label: "Ärtyneen suolen oireyhtymä ripulilla (IBS-D)", value: "ibs_d" },
      {
        label: "Ärtyneen suolen oireyhtymä ummetuksella (IBS-C)",
        value: "ibs_c",
      },
      {
        label: "Ärtyneen suolen oireyhtymä sekalaisilla vatsavaivoilla (IBS-M)",
        value: "ibs_m",
      },
      { label: "Haavainen paksusuolentulehdus (IBD UC)", value: "ibd_uc" },
      { label: "Crohnin tauti (IBD CD)", value: "ibd_cd" },
      { label: "Suolistosyöpä", value: "intestinal_cancer" },
    ],
  },
  {
    id: "22",
    title: "Epäiletkö itse jotain seuraavista suolistosairauksista?",
    type: "checkbox",
    name: "bowel_disease_suspects",
    options: [
      { label: "Ärtyneen suolen oireyhtymä ripulilla (IBS-D)", value: "ibs_d" },
      {
        label: "Ärtyneen suolen oireyhtymä ummetuksella (IBS-C)",
        value: "ibs_c",
      },
      {
        label: "Ärtyneen suolen oireyhtymä sekalaisilla vatsavaivoilla (IBS-M)",
        value: "ibs_m",
      },
      { label: "Epäilen jotain suolisto-ongelmaa", value: "something" },
      { label: "En epäile mitään suolisto-ongelmaa", value: "no" },
    ],
  },
  {
    id: "23",
    title: "Oletko ollut seuraavissa leikkauksissa?",
    type: "checkbox",
    name: "subsequent_surgeries",
    options: [
      { label: "Umpisuoli", value: "caecum" },
      { label: "Sappirakko", value: "gall_bladder" },
      { label: "Lihavuusleikkaus", value: "obesity_surgery" },
      { label: "Suolistoleikkaus", value: "intestinal_surgery" },
    ],
  },
  {
    id: "24",
    title: "Milloin viimeksi olet syönyt antibioottikuurin?",
    type: "radio",
    name: "last_antibiotics_course_time",
    options: [
      { label: "Olen parhaillaan antibioottikuurilla", value: "now" },
      {
        label: "Edellinen antibioottikuuri päättyi kahden viikon sisällä",
        value: "two_weeks",
      },
      {
        label: "Edellinen antibioottikuuri päättyi kuukauden sisällä",
        value: "month",
      },
      {
        label: "Edellinen antibioottikuuri päättyi kahden kuukauden sisällä",
        value: "two_months",
      },
      {
        label: "Edellinen antibioottikuuri päättyi vuoden sisällä",
        value: "year",
      },
      {
        label: "Edellinen antibioottikuuri päättyi yli vuosi sitten",
        value: "more_than_a_year",
      },
      { label: "En ole koskaan syönyt antibiootteja", value: "never" },
    ],
  },
  {
    id: "25",
    title: "Kuinka monta antibioottikuuria arvioit syöneesi elämäsi aikana?",
    type: "radio",
    name: "lifetime_antibiotics_course_estimate",
    options: [
      { label: "Alle 5", value: "units_0" },
      { label: "5-10", value: "units_5" },
      { label: "10-20", value: "units_10" },
      { label: "yli 20", value: "units_20" },
    ],
  },
  {
    id: "26",
    title:
      "Oliko sinulla useita antibioottikuureja ensimmäisen kolmen elinvuoden aikana?",
    type: "radio",
    name: "childhood_multiple_antibiotics_courses",
    options: [
      { label: "Kyllä, kolme tai yli", value: "more_than_3" },
      { label: "Ei, alle kolme", value: "less_than_3" },
      { label: "Ei ollenkaan", value: "never" },
      { label: "En tiedä / En halua vastata", value: "unknown" },
    ],
  },
  {
    id: "27",
    title: "Oletko syntynyt sektiolla vai alateitse?",
    type: "radio",
    name: "birth_position",
    options: [
      { label: "Aletiesynnytys", value: "down" },
      { label: "Sektio (keisarileikkaus)", value: "section" },
      { label: "En tiedä / En halua vastata", value: "unknown" },
    ],
  },
  {
    id: "28",
    title: "Onko sinua rintaruokittu lapsena?",
    type: "radio",
    name: "breastfed_child",
    options: [
      { label: "Kyllä, yli 3 kk", value: "over_three_months" },
      { label: "Kyllä, alle 3 kk", value: "under_three_months" },
      { label: "Ei", value: "no" },
      { label: "En tiedä / En halua vastata", value: "unknown" },
    ],
  },
  {
    id: "29",
    title:
      "Kuinka usein sinulla on seuraavia oireita? (Naisilla oireet, jotka eivät liity kuukautisiin.)",
    type: "matrix",
    questions: [
      {
        id: "29_1",
        title: "Ummetus",
        type: "radio",
        name: "constipation_frequency",
        options: [
          { label: "Ei koskaan", value: "never" },
          { label: "Alle 10 kertaa vuodessa", value: "less_than_10_a_year" },
          { label: "Kerran kuussa", value: "once_a_month" },
          { label: "Ainakin kerran viikossa", value: "once_a_week" },
          { label: "Joka päivä", value: "every_day" },
        ],
      },
      {
        id: "29_2",
        title: "Ripuli",
        type: "radio",
        name: "diarrhea_frequency",
        options: [
          { label: "Ei koskaan", value: "never" },
          { label: "Alle 10 kertaa vuodessa", value: "less_than_10_a_year" },
          { label: "Kerran kuussa", value: "once_a_month" },
          { label: "Ainakin kerran viikossa", value: "once_a_week" },
          { label: "Joka päivä", value: "every_day" },
        ],
      },
      {
        id: "29_3",
        title: "Turvotus",
        type: "radio",
        name: "swelling_frequency",
        options: [
          { label: "Ei koskaan", value: "never" },
          { label: "Alle 10 kertaa vuodessa", value: "less_than_10_a_year" },
          { label: "Kerran kuussa", value: "once_a_month" },
          { label: "Ainakin kerran viikossa", value: "once_a_week" },
          { label: "Joka päivä", value: "every_day" },
        ],
      },
      {
        id: "29_4",
        title: "Vatsakipu",
        type: "radio",
        name: "stomachache_frequency",
        options: [
          { label: "Ei koskaan", value: "never" },
          { label: "Alle 10 kertaa vuodessa", value: "less_than_10_a_year" },
          { label: "Kerran kuussa", value: "once_a_month" },
          { label: "Ainakin kerran viikossa", value: "once_a_week" },
          { label: "Joka päivä", value: "every_day" },
        ],
      },
      {
        id: "29_5",
        title: "Tavallista runsaammat ilmavaivat",
        type: "radio",
        name: "abnormal_flatulence_frequency",
        options: [
          { label: "Ei koskaan", value: "never" },
          { label: "Alle 10 kertaa vuodessa", value: "less_than_10_a_year" },
          { label: "Kerran kuussa", value: "once_a_month" },
          { label: "Ainakin kerran viikossa", value: "once_a_week" },
          { label: "Joka päivä", value: "every_day" },
        ],
      },
      {
        id: "29_6",
        title: "Vatsakrampit",
        type: "radio",
        name: "stomach_cramps_frequency",
        options: [
          { label: "Ei koskaan", value: "never" },
          { label: "Alle 10 kertaa vuodessa", value: "less_than_10_a_year" },
          { label: "Kerran kuussa", value: "once_a_month" },
          { label: "Ainakin kerran viikossa", value: "once_a_week" },
          { label: "Joka päivä", value: "every_day" },
        ],
      },
    ],
  },
  {
    id: "30",
    title:
      "Mikä alla olevista kuvastaa sinulle tyypillistä ulostetta parhaiten?",
    type: "checkbox",
    name: "composition_of_stool",
    options: [
      {
        label: "Tyyppi 1: erillisiä, kovia, vaikeasti ulostuvia kokkareita",
        value: "type_1_hard",
      },
      {
        label: "Tyyppi 2: yhtenäinen, kiinteä, selvästi kuhmurainen",
        value: "type_2_solid_bumpy",
      },
      {
        label: "Tyyppi 3: yhtenäinen, kiinteä, rakoja pinnassa",
        value: "type_3_solid_surface_cracks",
      },
      {
        label: "Tyyppi 4: yhtenäinen, pehmeä ja sileä",
        value: "type_4_solid_smooth",
      },
      {
        label: "Tyyppi 5: pehmeitä, erillisiä klönttejä",
        value: "type_5_soft_discrete",
      },
      { label: "Tyyppi 6: kuohkeaa, puuromaista", value: "type_6_fluffy" },
      { label: "Tyyppi 7: täysin nestemäistä", value: "type_7_liquid" },
    ],
  },
];

export const QUESTIONS_SE = [
  {
    id: 1,
    title: "Är du gravid?",
    type: "radio",
    sex: "female",
    name: "pregnant",
    options: [
      { label: "Ja", value: "yes" },
      { label: "Nej", value: "no" },
    ],
  },
  {
    id: 2,
    title: "Ammar du för tillfället?",
    type: "radio",
    sex: "female",
    name: "breastfeeding",
    options: [
      { label: "Ja", value: "yes" },
      { label: "Nej", value: "no" },
    ],
  },
  {
    id: 3,
    title: "Vad beskriver bäst dina kostvanor?",
    type: "radio",
    name: "diet_sweden",
    options: [
      { label: "Icke-vegetarisk kost", value: "mixed" },
      { label: "Vegetarisk kost", value: "vegetarian" },
      { label: "Vegansk kost", value: "vegan" },
      { label: "Annat", value: "other" },
    ],
  },
  {
    id: 4,
    title:
      "Har du någon sjukdom eller besvär som du medicinerar eller vårdas för kontinuerligt?",
    type: "radio",
    name: "medicated_illness",
    options: [
      { label: "Ja", value: "yes" },
      { label: "Nej", value: "no" },
    ],
  },
  /*
  {
    id: 5,
    title: "Vänligen uppge:",
    type: "string",
    name: "medicated_illness_text"
  },
  */
  {
    id: 6,
    title: "Tar du något läkemedel eller kosttillskott regelbundet?",
    type: "radio",
    name: "medicine_or_suppliments",
    options: [
      { label: "Ja", value: "yes" },
      { label: "Nej", value: "no" },
    ],
  },
  /*
  {
    id: 7,
    title: "Vänligen uppge vilket eller vilka:",
    type: "string",
    name: "medicine_or_suppliments_text",
  },
  */
  {
    id: 8,
    title: "Varför tog du blodprovet?",
    type: "radio",
    name: "reason_for_test",
    options: [
      {
        label: "Hälsokontroll - mår bra, vill kontrollera mina värden",
        value: "health_followup",
      },
      { label: "Misstänker sjukdom - mår inte bra", value: "disease_suspect" },
      {
        label:
          "Följer upp tidigare blodprov för att följa utveckling av värden",
        value: "blood_test_folllowup",
      },
      { label: "Följer en kronisk sjukdom", value: "chronic_disease_followup" },
      { label: "Vill ej svara", value: "no_answer" },
    ],
  },
];

export const QUESTIONS_SE_EN = [
  {
    id: 1,
    title: "Are you pregnant?",
    type: "radio",
    sex: "female",
    name: "pregnant",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
  },
  {
    id: 2,
    title: "Are you currently breastfeeding?",
    type: "radio",
    sex: "female",
    name: "breastfeeding",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
  },
  {
    id: 3,
    title: "What best describes your diet?",
    type: "radio",
    name: "diet_sweden",
    options: [
      { label: "Non-vegetarian diet", value: "mixed" },
      { label: "Vegetarian diet", value: "vegetarian" },
      { label: "Vegan diet", value: "vegan" },
      { label: "Other", value: "other" },
    ],
  },
  {
    id: 4,
    title:
      "Do you have any illness or discomfort that you are medicating or caring for continuously?",
    type: "radio",
    name: "medicated_illness",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
  },
  /*
  {
    id: 5,
    title: "Please specify:",
    type: "string",
    name: "medicated_illness_text"
  },
  */
  {
    id: 6,
    title: "Do you take any medication or supplements regularly?",
    type: "radio",
    name: "medicine_or_suppliments",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
  },
  /*
  {
    id: 7,
    title: "Please specify which:",
    type: "string",
    name: "medicine_or_suppliments_text",
  },
  */
  {
    id: 8,
    title: "Why did you take the blood test?",
    type: "radio",
    name: "reason_for_test",
    options: [
      {
        label: "Health check - feel good, want to check my values",
        value: "health_followup",
      },
      { label: "Suspect disease - feel unwell", value: "disease_suspect" },
      {
        label:
          "Following up previous blood test to follow development of values",
        value: "blood_test_folllowup",
      },
      {
        label: "Following a chronic disease",
        value: "chronic_disease_followup",
      },
      { label: "Do not want to answer", value: "no_answer" },
    ],
  },
];
