import React, { Component } from "react";
import AnimateHeight from "react-animate-height";
import Logo from "../Logo";

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navOpen: false,
      navHeight: 0,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll.bind(this));
    const mql = window.matchMedia("(min-width: 980px)");
    mql.addEventListener("change", this.handleMediaChange.bind(this));
    this.handleMediaChange(mql);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll.bind(this));
    const mql = window.matchMedia("(min-width: 980px)");
    mql.removeEventListener("change", this.handleMediaChange.bind(this));
  }

  handleMediaChange(mediaQueryList) {
    if (mediaQueryList.matches) {
      this.setState({
        navHeight: "auto",
      });
    } else {
      this.setState({
        navHeight: 0,
      });
    }
  }

  handleScroll(e) {
    if (
      e.currentTarget.pageYOffset >
      3.75 * parseFloat(getComputedStyle(document.documentElement).fontSize)
    ) {
      this.setState({ headerFixed: true });
    } else {
      this.setState({ headerFixed: false });
    }
  }

  onClickToggle() {
    clearTimeout(this.navToggleTimeout);

    this.setState(
      (state) => ({
        navOpen: !state.navOpen,
      }),
      () => {
        if (!this.state.navOpen) {
          this.setState({
            navToggleRotate: false,
            navHeight: 0,
          });
          this.navToggleTimeout = setTimeout(() => {
            this.setState({
              navToggleCollapse: false,
            });
          }, 200 / 1.618);
        } else {
          this.setState({
            navToggleCollapse: true,
            navHeight: "auto",
          });
          this.navToggleTimeout = setTimeout(() => {
            this.setState({
              navToggleRotate: true,
            });
          }, 200 / 1.618);
        }
      },
    );
  }

  renderLogo() {
    if (this.props.company) {
      return (
        <div className="nav-container-logo">
          <div className="nav-container-logo__full">
            <Logo />
          </div>
        </div>
      );
    } else {
      return (
        <div className="nav-container-logo">
          <div className="nav-container-logo__symbol">
            <Logo logoType="symbol" />
          </div>
          <div className="nav-container-logo__type">
            <Logo logoType="noLogo" />
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div
        className={`nav-container ${
          this.props.company && "nav-container--invert"
        }`}
      >
        <div className="nav-container__inner">
          <div className="nav-container__left">
            {this.renderLogo()}
            <div
              className="nav-container-toggle"
              onClick={this.onClickToggle.bind(this)}
            >
              <div
                className={`nav-toggle ${
                  this.state.navToggleRotate ? "nav-toggle--rotate" : ""
                } ${
                  this.state.navToggleCollapse ? "nav-toggle--collapse" : ""
                }`}
              >
                <div className="nav-toggle__inner" />
              </div>
            </div>
          </div>
          <div className="nav-container__right">
            <AnimateHeight
              className="nav-list"
              duration={200 / 1.618}
              height={this.state.navHeight}
              onAnimationEnd={() =>
                this.setState({ heightAnimationEnded: true })
              }
              style={
                this.state.heightAnimationEnded ? { overflow: "visible" } : {}
              }
              applyInlineTransitions={false}
            >
              <div className="nav-list__inner">{this.props.leftLinks}</div>
              <div className="nav-list__inner">{this.props.rightLinks}</div>
            </AnimateHeight>
          </div>
        </div>
      </div>
    );
  }
}

export default Navigation;
