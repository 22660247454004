import React, { Component } from "react";

import { withTranslation } from "react-i18next";
import { resolveUrl } from "../links";

import env from "../env";
import i18n from "../localization/i18n";
import { Flipper } from "../flipper";
import { Globe } from "react-feather";

const changeLanguage = (lng) => {
  i18n.changeLanguage(lng);
  localStorage.setItem("selectedLanguage", lng);
  window.location.reload(false);
};

const availableLanguages = () => {
  const languageString = env.LOCALIZATION_ALLOWED_LOCALES || "";
  const languages = languageString.split(",").map((lang) => lang.trim());
  return languages;
};

class LoginFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    if (this.props?.features) {
      Flipper.setFeatures(this.props?.features);
    }
  }

  renderIcon(icon) {
    return <div className="login-language-selector-icon">{icon}</div>;
  }

  render() {
    const localeSwitcherEnabled = Flipper.enabled("localeSwitcher");
    const currentLanguage = i18n.language;
    const { t } = this.props;

    return (
      <div className="margin-1-5 margin-top">
        <div className="text-align-center justify-center flex negative-margin-0-25">
          <div className="padding-0-25">
            <a href={t("main_navigation.store_link")} className="link">
              {t("login.back_link")}
            </a>
          </div>
          <div className="padding-0-25">
            <a href={resolveUrl("loginFaqUrl")} className="link">
              {t("login.faq_link")}
            </a>
          </div>
          {localeSwitcherEnabled && (
            <>
              {this.renderIcon(<Globe />)}
              <select
                value={currentLanguage}
                className="login-language-selector"
                onChange={(e) => changeLanguage(e.target.value)}
              >
                {availableLanguages().map((lang) => (
                  <option key={lang} value={lang}>
                    {t("languages." + lang)}
                  </option>
                ))}
              </select>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(LoginFooter);
