import React, { useCallback, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import calendar from "../../assets/calendar.svg";
import chevronUp from "../../assets/chevron-up.svg";
import chevronDown from "../../assets/chevron-down.svg";
import externalLink from "../../assets/external-link.svg";
import printer from "../../assets/printer.svg";
import helpCircle from "../../assets/help-circle.svg";
import droplet from "../../assets/droplet.svg";
import { Pie } from "@nivo/pie";
import styles from "./ResultSummaryCard.module.scss";
import classNames from "classnames";
import { formatStringDate } from "../../helpers.js";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NoticeText from "../NoticeText";
import env from "../../env";
const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = useCallback(() => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [setIsMobile]);

  useEffect(() => {
    setIsMobile(window.innerWidth < 720);
  }, []);

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      return window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return isMobile;
};

const ResultSummaryCard = ({ order }) => {
  const { t } = useTranslation();
  const { summary } = order;

  let sections = [
    {
      label: t("result_summary.results_below_range"),
      borderColor: "#ef686f",
    },
    {
      label: t("result_summary.results_within_range"),
      borderColor: "#43c45f",
    },
    {
      label: t("result_summary.results_above_range"),
      borderColor: "#ef686f",
    },
  ];

  let noReferenceSections = [
    {
      label: t("result_summary.outside_references"),
    },
  ];

  sections[0].tests = summary.below_reference;
  sections[1].tests = summary.within_reference;
  sections[2].tests = summary.above_reference;
  noReferenceSections[0].tests = summary.no_reference;

  return (
    <>
      <div className="card">
        <div
          className="flex padding-0-75"
          style={{ justifyContent: "space-between" }}
        >
          {renderInfo(order.order_number, order.sampling_time, t)}
          <DataPie sections={sections} />
        </div>
        <DropDownSections sections={sections} />
        {summary.no_reference && summary.no_reference.length > 0 && (
          <DropDownSections sections={noReferenceSections} />
        )}

        {renderActiveOrderActionButtons(order.order_number, t)}
      </div>
      <NoticeText text={t("result_summary.notice_text")} />
    </>
  );
};

const Section = ({
  label,
  num,
  borderColor,
  tests,
  index,
  setSectionsOpen,
  sectionsOpen,
}) => {
  const [open, setOpen] = useState(false);
  const isMobile = useIsMobile();
  const history = useHistory();

  useEffect(() => {
    if (sectionsOpen) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [sectionsOpen]);

  const sectionHeaderStyles = classNames(styles.sectionHeader, {
    [styles.redBorder]: borderColor === "#ef686f",
    [styles.greenBorder]: borderColor === "#43c45f",
    [styles.whiteBorder]: !borderColor,
  });

  return (
    <div className={styles.dropDownSectionWrapper}>
      <div style={{ position: "relative" }}>
        <button
          className={sectionHeaderStyles}
          onClick={() => {
            setOpen(!open);
            !isMobile && setSectionsOpen(!sectionsOpen);
          }}
        >
          <h3 className="size-medium weight-500">
            <span
              className="weight-700"
              style={{
                color: borderColor ? borderColor : "#626e8e",
                marginRight: "0.5rem",
              }}
            >
              {tests.length}
            </span>
            {label}
          </h3>

          <SVG src={open ? chevronUp : chevronDown} className="color-gray" />
        </button>
      </div>
      {open && (
        <div className={styles.testBulletsContainer}>
          {tests.map((test) => {
            return (
              <button
                onClick={() => {
                  history.push({ state: { hash: Object.keys(test)[0] } });
                }}
                key={test}
                className={styles.resultBullet}
              >
                {test[Object.keys(test)[0]]}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

const DropDownSections = ({ sections }) => {
  const [sectionsOpen, setSectionsOpen] = useState(false);

  return (
    <div className={styles.dropDownSelectionsWrapper}>
      {sections.map((section, i) => {
        return (
          <Section
            key={section.label}
            {...section}
            index={i}
            sectionsOpen={sectionsOpen}
            setSectionsOpen={setSectionsOpen}
          />
        );
      })}
    </div>
  );
};

const renderInfo = (orderNumber, samplingDate, t) => {
  return (
    <div className={styles.infoContainer}>
      <h1 className="size-medium weight-500">
        {t("result_summary.summary_of_results")}
      </h1>
      <div className="margin-0-5 margin-bottom">
        <IconWithLabel
          label={t("result_summary.order_number") + ": #" + orderNumber}
        />
      </div>
      <IconWithLabel
        icon={droplet}
        label={t("result_summary.blood_test")}
        inline
      />
      <IconWithLabel
        icon={calendar}
        label={formatStringDate(samplingDate)}
        inline
      />
    </div>
  );
};

export const renderActiveOrderActionButtons = (orderId, t) => {
  const isPuhti = env.APP_ID === "puhti";

  const links = {
    helpLink: isPuhti
      ? "https://www.puhti.fi/tietopaketit/laboratoriokokeiden-tulokset-ja-viitearvot/"
      : "https://vital.se/",
    reorder: isPuhti
      ? "https://www.puhti.fi/tuotteet"
      : "https://vital.se/halsokontroller/",
  };

  return (
    <div className={styles.bottomActionsWrapper}>
      {isPuhti && (
        <IconWithLabel
          icon={helpCircle}
          label={t("result_summary.help_for_resut_interpretation")}
          onClick={() => {
            window.open(links.helpLink);
          }}
          button
          blue
        />
      )}
      <IconWithLabel
        icon={printer}
        label={t("order_selector.print_report_pdf")}
        button
        blue
        onClick={() => {
          window.open(`/orders/${orderId}/pdf`);
        }}
      />
      <IconWithLabel
        icon={externalLink}
        label={t("result_summary.reorder_test")}
        onClick={() => {
          window.open(links.reorder);
        }}
        button
        blue
      />
    </div>
  );
};

const IconWithLabel = ({
  icon,
  label,
  button,
  inline,
  blue,
  gray = true,
  onClick,
}) => {
  const Component = button ? "button" : "div";

  const labelStyles = classNames(styles.smallLabel, {
    [styles.blue]: blue,
    [styles.gray]: !blue && gray,
  });

  return (
    <Component className={styles.iconWithLabelWrapper} onClick={onClick}>
      {icon && <SVG src={icon} className={styles.labelIcon} />}
      <small className={labelStyles}>{label}</small>
    </Component>
  );
};

const DataPie = ({ sections }) => {
  const withinRangesNum = sections[1].tests.length;
  const outsideRangesNum = [...sections[0].tests, ...sections[2].tests].length;

  const allRangesNum =
    sections[0].tests.length +
    sections[1].tests.length +
    sections[2].tests.length;

  const { t } = useTranslation();
  const data = [
    {
      id: "1",
      label: t("result_summary.within_range"),
      value: withinRangesNum,
      color: "#43c45f",
    },
    {
      id: "2",
      label: t("result_summary.outside_range"),
      value: allRangesNum - withinRangesNum,
      color: "#ef686f",
    },
  ];

  let percentageDisplay = Math.floor((withinRangesNum / allRangesNum) * 100);
  let outsideRangesPercentage = Math.floor(
    (outsideRangesNum / allRangesNum) * 100,
  );
  const isMobile = useIsMobile();

  return (
    <div className={styles.pieContainer}>
      <div className={styles.pieHelper}>
        <h1 className={styles.absoluteCenter}>
          {!isNaN(percentageDisplay) && percentageDisplay}
          <span className="size-small">%</span>
        </h1>
        <Pie
          data={data}
          width={isMobile ? 75 : 110}
          height={isMobile ? 75 : 110}
          colors={(data) => {
            return data.color;
          }}
          innerRadius={0.8}
          tooltip={(a, b, c) => {
            return (
              <div
                style={{
                  whiteSpace: "no-wrap",
                  minWidth: a.id === "1" ? 165 : 200,
                }}
              >
                <p>
                  {a.id === "1"
                    ? `${percentageDisplay}% ${t(
                        "result_summary.within_range",
                      )}`
                    : `${outsideRangesPercentage}% ${t(
                        "result_summary.outside_range",
                      )}`}
                </p>
              </div>
            );
          }}
          padAngle={0}
          fit={false}
          borderWidth={0}
          enableRadialLabels={false}
          enableSlicesLabels={false}
          enableLegends={false}
          animate={false}
          isInteractive
        />
      </div>
      <div>
        <h1 className={styles.pieLabel}>
          {t("result_summary.within_range").charAt(0).toUpperCase() +
            t("result_summary.within_range").slice(1)}
        </h1>
      </div>
    </div>
  );
};

export default ResultSummaryCard;
