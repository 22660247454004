import React, { Component, useRef, useState } from "react";
import { Checkbox, CheckboxGroup } from "react-checkbox-context";
import { RadioGroup, Radio } from "react-radio-group";
import Select from "../components/Select";
import update from "immutability-helper";
import { MarkdownEditor } from "./Markdown/Markdown";
class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkboxObject: this.props.value,
    };
    this.textInputRef = React.createRef();

    this.onChange = this.onChange.bind(this);
  }

  components = {
    radio: RadioGroup,
    checkbox: CheckboxGroup,
    select: Select,
  };

  convertValueObjectToArray(stateObject) {
    let valueArray = [];

    Object.keys(stateObject).forEach((key) => {
      if (stateObject[key]) {
        valueArray.push(key);
      }
    });

    return valueArray;
  }

  convertValueArrayToObject(valueArray) {
    const oldState = this.state.checkboxObject;

    let stateObject = {};

    Object.keys(oldState).map((key) => {
      if (valueArray.find((v) => v === key)) {
        stateObject[key] = true;
      } else {
        stateObject[key] = false;
      }
    });

    return update(oldState, { $merge: stateObject });
  }

  onChange(value, event, attribute) {
    if ((event && !event?.target) || value.target) {
      //this less than optimal solutions fixes a problem where
      //sometimes value and event arguments are reversed

      let isActuallyValue = event;
      event = value;
      value = isActuallyValue;
    }

    const { name } = this.props;

    if (!event) {
      //data from textarea
      this.props.onChange(value, attribute);
      return;
    }

    if (event && event.target.type === "checkbox") {
      let newValue = null;
      // Check from previous state if checkbox was checked and then update the values accordingly.

      if (value.includes(event.target.value)) {
        newValue = value; //.filter((v) => v !== event.target.value); <-- these messed up state change for checkbox for some reason
      } else {
        newValue = value; //.concat(event.target.value); <-- and this
      }

      this.props.onChange(this.convertValueArrayToObject(newValue), name);
    } else if (event && event.target.type === "radio") {
      this.props.onChange(value, name);
    } else {
      this.props.onChange(event.target.value, name);
    }
  }

  renderOptions() {
    const { name, options, type, id } = this.props;
    return options.map((option, index) => {
      if (type === "radio") {
        return (
          <div className="radio" key={index}>
            <Radio id={`radio_${id}_${index}`} value={option.value} />
            <label htmlFor={`radio_${id}_${index}`}>{option.label}</label>
          </div>
        );
      } else if (type === "checkbox") {
        return (
          <div className="checkbox size-normal" key={index}>
            <Checkbox id={`checkbox_${id}_${index}`} value={option.value} />
            <label htmlFor={`checkbox_${id}_${index}`}>{option.label}</label>
          </div>
        );
      } else if (type === "select") {
        return (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        );
      }
    });
  }

  render() {
    const { title, type, name, value, subQuestion } = this.props;

    const Component = this.components[type || "radio"];
    if (subQuestion) {
      return (
        <div className="padding-0-75">
          <h3 className="size-normal weight-500 color-purple margin-0-5 margin-bottom padding-0-25 padding-bottom border-bottom border-color-blue-lighter">
            {title}
          </h3>
          <input value="" type="text" id="asd" />
          <div className="child-margins-y-0-5">
            <Component
              className="child-margins-y-0-5"
              name={name}
              value={value}
              values={
                type === "checkbox" ? this.convertValueObjectToArray(value) : ""
              }
              selectedValue={value}
              onChange={() => this.onChange()}
            >
              {this.renderOptions()}
            </Component>
          </div>
        </div>
      );
    } else {
      return (
        <div className="card padding-1">
          {title && (
            <h2 className="size-medium weight-500 margin-0-5 margin-bottom">
              {title}
            </h2>
          )}
          <div className="child-margins-y-0-5">
            <Component
              className="child-margins-y-0-5"
              name={name}
              value={value}
              values={
                type === "checkbox" ? this.convertValueObjectToArray(value) : ""
              }
              selectedValue={value}
              onChange={this.onChange}
            >
              {this.renderOptions()}
            </Component>
            <QuestionMarkdown props={this.props} onChange={this.onChange} />
          </div>
        </div>
      );
    }
  }
}

const QuestionMarkdown = ({ props, onChange }) => {
  const textRef = useRef();
  const { name, profileData, t } = props;

  const getTextAreaAttributes = () => {
    if (name === "medicine_or_suppliments" && profileData[name] === "yes") {
      return {
        textAreaLabel: t("profile.please_state_which") + ":",
        textAreaVisible: true,
        attribute: "medicine_or_suppliments_text",
      };
    }
    if (name === "medicated_illness" && profileData[name] === "yes") {
      return {
        textAreaLabel: t("profile.please_state") + ":",
        textAreaVisible: true,
        attribute: "medicated_illness_text",
      };
    }
    return {};
  };

  const { textAreaLabel, textAreaVisible, attribute } = getTextAreaAttributes();
  const [value, setValue] = useState(profileData[attribute]);

  return (
    <>
      <input
        type="text"
        ref={textRef}
        value={value}
        style={{ display: "none" }}
        autoFocus
      />
      {textAreaVisible && (
        <MarkdownEditor
          propsDefaultValue={value}
          editorLabel={textAreaLabel}
          textInputRef={textRef}
          onChange={(value) => {
            setValue();
          }}
          onBlur={(value) => {
            onChange(value, null, attribute);
          }}
          hidePreviewButton
          toolbarConfig={{
            display: [],
          }}
        />
      )}
    </>
  );
};

export default Question;
