import { ReviewContext } from "./ReviewProvider";
import { MarkdownEditor } from "../Markdown/Markdown";
import { useCategoryAnswerString } from "./CategorySummary";
import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import OrderChangeSpinner from "../OrderChangeSpinner/OrderChangeSpinner";
import axios from "axios";

const FinalReview = ({ resultCategories, orderId, doctorId }) => {
  const {
    doctorSelections,
    finalReportGenerated,
    setFinalReportGenerated,
    createStatement,
  } = useContext(ReviewContext);
  const [textAreaToggled, setTextAreaToggled] = useState(false);
  //const textInputRef = useRef(null);
  const { t } = useTranslation();
  const statementsString = useCategoryAnswerString(doctorSelections);
  const [textValue, setTextValue] = useState(statementsString || " ");

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState();
  useEffect(() => {
    if (!finalReportGenerated) {
      setTextAreaToggled(false);
    }
  }, [finalReportGenerated]);

  const saveReport = () => {
    setSaving(true);
    axios
      .put("/doctor/api/commerce_orders/" + orderId + "/doctor_review", {
        doctor_review: {
          statement: textValue,
          published: true,
          doctor_id: doctorId,
        },
      })
      .then(() => {
        setSaving(false);
        window.location = "/doctor/orders";
      })
      .catch(() => {});
  };

  return (
    <div className="card padding-1">
      {!textAreaToggled ? (
        <p className="text-center margin-2 color-gray">
          {loading ? (
            <div className="margin-3 margin-bottom">
              {t("loading_spinner.loading")}
            </div>
          ) : (
            <button
              className="button"
              onClick={() => {
                setLoading(true);
                createStatement(
                  (statement) => {
                    setTextValue(statement);
                    setFinalReportGenerated(true);
                    setTextAreaToggled(true);
                    setLoading(false);
                  },
                  null,
                  null,
                  false
                );
              }}
            >
              {t("doctor_review.create_final_report")}
            </button>
          )}
        </p>
      ) : (
        <div>
          {textValue && (
            <MarkdownEditor
              draftJsMarkdown={textValue}
              onChange={(value) => {
                setTextValue(value);
              }}
            >
              <button
                className="button margin-1 margin-right"
                onClick={() => {
                  saveReport();
                }}
              >
                {saving ? (
                  <OrderChangeSpinner visible />
                ) : (
                  t("doctor_review.save_review")
                )}
              </button>
            </MarkdownEditor>
          )}
        </div>
      )}
    </div>
  );
};

export default FinalReview;
