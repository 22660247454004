import { Container } from "unstated";
import axios from "axios";
import denormalize from "denormalize-jsonapi";

export default class MicrobiomeOrdersContainer extends Container {
  state = {
    list: [],
    active: null,
    loading: false,
    error: null,
  };

  setActive = (active) => {
    this.setState({
      active,
    });
  };

  load = () => {
    this.setState({
      error: null,
      loading: true,
    });
    axios
      .get("/api/microbiome_orders")
      .then((response) => {
        const orders = denormalize(response.data).data;
        const active = orders.length ? orders[0] : null;
        this.setState({
          list: orders,
          loading: false,
          error: null,
          active,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: error,
        });
      });
  };
}
