import React, { Component } from "react";

class Select extends Component {
  render() {
    return (
      <div className="select">
        <select onChange={this.props.onChange} value={this.props.value}>
          {this.props.children}
        </select>
      </div>
    );
  }
}

export default Select;
