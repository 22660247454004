import chroma from "chroma-js";

const primaryColors = [
  "#00ADA0",
  "#30AC73",
  "#74AB9C",
  "#8389AC",
  "#A283AC",
  "#794A95",
  "#4F61A8",
  "#297AAC",
  // remove reds because they indicate bad results
  // "#e04444",
  // "#C4372A",
  // "#C43E6A",
  "#C05D8F",
  "#13AFBF",
  "#A3AB2C",
  "#889799",
  "#AFA25D",
  "#BB782B",
  "#637c7c",
  "#B68C74",
  "#696FAD",
];

export function getColorCodes() {
  return generateFullPalette(primaryColors);
}

function generateFullPalette(primaryColors) {
  let result = [];

  primaryColors.forEach((color) => {
    result.push(chroma(color).hex());
  });
  primaryColors.forEach((color) => {
    result.push(
      chroma(color)
        .set("hsl.h", chroma(color).get("hsl.h") + 20)
        .set("hsl.l", chroma(color).get("hsl.l") + 0.3)
        .hex(),
    );
  });
  primaryColors.forEach((color) => {
    result.push(
      chroma(color)
        .set("hsl.h", chroma(color).get("hsl.h") + 10)
        .set("hsl.l", chroma(color).get("hsl.l") - 0.2)
        .hex(),
    );
  });
  primaryColors.forEach((color) => {
    result.push(
      chroma(color)
        .set("hsl.h", chroma(color).get("hsl.h") + 10)
        .set("hsl.l", chroma(color).get("hsl.l") + 0.15)
        .hex(),
    );
  });
  primaryColors.forEach((color) => {
    result.push(
      chroma(color)
        .set("hsl.h", chroma(color).get("hsl.h") + 10)
        .set("hsl.l", chroma(color).get("hsl.l") - 0.1)
        .hex(),
    );
  });

  return result;
}
