import React from "react";
import Logo from "./Logo.js";

class Footer extends React.Component {
  render() {
    return (
      <section className="bg-white padding-1">
        <div className="logo logo--small">
          <Logo />
        </div>
      </section>
    );
  }
}

export default Footer;
