import React, { useEffect } from "react";
import SVG from "react-inlinesvg";

import LoginFooter from "../components/LoginFooter";

import { Box } from "react-feather";
import logoSymbol from "../assets/logo-symbol.svg";
import EmptyLayout from "../layouts/EmptyLayout";

import { withTranslation } from "react-i18next";

function Maintenance() {
  const { t } = this.props;

  useEffect(() => {
    const interval = setInterval(() => {
      window.location = `${window.location.href}/..`;
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <EmptyLayout>
      <div className="padding-1">
        <>
          <div className="split-card">
            <div className="split-card__left bg-purple">
              <SVG src={logoSymbol} className={`login-logo`} />
            </div>
            <div className="split-card__right">
              <React.Fragment>
                <div className="flex justify-center margin-1 margin-bottom">
                  <div className="icon-badge bg-purple">
                    <Box />
                  </div>
                </div>
                <div className="margin-1 margin-bottom child-margins-y-0-25 text-align-center">
                  <h1 className="size-large color-purple weight-500">
                    {t("maintenance.header")}
                  </h1>
                  <p>{t("maintenance.description")}</p>
                </div>
                <div className="margin-1 margin-top" />
                <div className="margin-1 margin-top text-align-center" />
              </React.Fragment>
            </div>
          </div>
          <LoginFooter />
        </>
      </div>
    </EmptyLayout>
  );
}

export default withTranslation()(Maintenance);
